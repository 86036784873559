import React, { useContext, useMemo, useState } from "react";
import { assoc, curry, has, isEmpty, lensPath, map, omit, view } from "ramda";
import { useParams } from "react-router-dom";
import { ContextEDH } from "@context/ContextEDH";
import { CampusCategory } from "@context/base.reducer";
import cx from "classnames";
import { Button, Card, Checkbox, Loading, Pill } from "@foris/avocado-ui";
import { Campus } from "@models/ISchema";
import { AdaptedGroup } from "../../models";
import { IParams } from "@models/IParams";
import { useCreateSubgroups } from "../../hooks/useCreateSubgroup";
import { Icon } from "@foris/avocado-icons";
import css from "./subgroupsCreation.module.scss";

interface AdaptedGrouper extends CampusCategory {
  campuses: string[];
}

interface Props {
  parentGroup: AdaptedGroup;
  setDisplayCreation: (display: boolean) => void;
  subgroups: AdaptedGroup[];
  requestSubgroups: () => void;
  campuses: Campus[];
}

const getCampusGroupersPills = (groupers: AdaptedGrouper[] = [], campusCode = "") => {
  return (
    <div className={css.campusItem_groupers}>
      {groupers
        ?.filter(grouper => grouper.campuses.includes(campusCode))
        .map(grouper => (
          <Pill key={grouper.code} className={css.grouperLabel}>
            {grouper.name}
          </Pill>
        ))}
    </div>
  );
};

const getCampusIdsFromCampusCodes = (
  campuses: Campus[],
  campusCodes: Record<Campus["code"], boolean>,
) => {
  const idsRecord: Record<Campus["id"], boolean> = {};

  campuses?.forEach(campus => {
    if (campusCodes?.[campus?.code]) {
      idsRecord[campus?.id] = true;
    }
  });

  return idsRecord;
};

const SubgroupsCreation: React.FC<Props> = ({
  parentGroup,
  setDisplayCreation,
  subgroups,
  requestSubgroups,
  campuses,
}) => {
  const { state } = useContext(ContextEDH);
  const { scenario, origin }: IParams = useParams();
  const [{ isLoading }, submitCreations] = useCreateSubgroups({ scenario, origin });
  const [selectedCampusCodes, setSelectedCampusCodes] = useState<Record<Campus["code"], boolean>>(
    {},
  );
  const [isGroupersOpen, setIsGroupersOpen] = useState(false);
  const [selectedGroupers, setSelectedGroupers] = useState<{
    [grouperCode: string]: CampusCategory & {
      campuses: string[];
    };
  }>({});

  const groupers = state?.base?.campusCategoriesConfig?.campus_categories;

  const alreadyCreatedCampusesByCodes = useMemo(() => {
    const getCampusCode = view(lensPath(["campus", "code"]));
    const campusCodes = map(getCampusCode, subgroups ?? []);
    return new Set(campusCodes);
  }, [subgroups]);

  const enabledCampuses = useMemo(() => {
    return campuses?.filter(campus => !alreadyCreatedCampusesByCodes.has(campus?.code));
  }, [campuses, alreadyCreatedCampusesByCodes]);

  const enabledCampusesCodes = useMemo(() => {
    return enabledCampuses?.map(campus => campus?.code);
  }, [enabledCampuses]);

  const isAllCampusesSelected = useMemo(() => {
    return enabledCampuses?.length === Object.keys(selectedCampusCodes).length;
  }, [selectedCampusCodes, enabledCampuses]);

  const toggle = curry((campusCode: Campus["code"], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      setSelectedCampusCodes(assoc(campusCode, true, selectedCampusCodes));
    } else {
      setSelectedCampusCodes(omit([campusCode], selectedCampusCodes));
    }
  });

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      setSelectedCampusCodes(
        enabledCampuses.reduce((acc, campus) => ({ ...acc, [campus?.code]: true }), {}),
      );
    } else {
      setSelectedCampusCodes({});
      setSelectedGroupers({});
    }
  };

  const handleToggleGrouper = (isGrouperSelected: boolean, grouper: CampusCategory) => {
    if (isGrouperSelected) {
      const campusesCodes = grouper?.campus.map(campus => campus?.code);

      setSelectedGroupers({
        ...selectedGroupers,
        [grouper?.code]: {
          ...grouper,
          campuses: campusesCodes,
        },
      });

      const campusesCodesToSelect = [...enabledCampusesCodes]?.filter(
        campusId => campusesCodes.includes(campusId) && !selectedCampusCodes[campusId],
      );

      setSelectedCampusCodes({
        ...selectedCampusCodes,
        ...campusesCodesToSelect.reduce(
          (acc, campuesCode) => ({ ...acc, [campuesCode]: true }),
          {},
        ),
      });
    } else {
      const newSelectedGroupers = { ...selectedGroupers };
      const grouperToDelete = newSelectedGroupers[grouper?.code];

      delete newSelectedGroupers[grouper?.code];

      const campusesCodesToUnselect = grouperToDelete?.campuses.filter(
        campusCode =>
          selectedCampusCodes?.[campusCode] &&
          Object.values(newSelectedGroupers).every(
            ({ campuses }) => !campuses.includes(campusCode),
          ),
      );

      const newSelectedCampusIds = { ...selectedCampusCodes };
      campusesCodesToUnselect.forEach(campusCode => {
        delete newSelectedCampusIds[campusCode];
      });

      setSelectedCampusCodes(newSelectedCampusIds);
      setSelectedGroupers(newSelectedGroupers);
    }
  };

  return (
    <section className={css.main}>
      {isLoading && <Loading />}

      <Card.Simple className={cx("container-row", css.card)}>
        <Card.Content>
          <header className={css.card__header}>
            <h3 className={css.card__header__title}>Agregar Subgrupos</h3>
            <span className={css.card__header__subtitle}>
              Seleccione las sedes para agregar subgrupos o desmarque las sedes para eliminarlas del
              grupo principal.
            </span>
          </header>

          {!!state?.base?.campusCategoriesConfig?.enabled && (
            <div className={cx(css.groupers, isGroupersOpen && css.groupers__open)}>
              <div className={css.groupers_header}>
                <button
                  className={cx(css.groupersTrigger, isGroupersOpen && css.groupersTrigger__open)}
                  onClick={() => setIsGroupersOpen(!isGroupersOpen)}
                >
                  <Icon name="chevron-down" />
                </button>
                Agrupadores de sede
              </div>

              <div className={css.groupers_content}>
                <Checkbox
                  checked={isAllCampusesSelected}
                  labelRight="Todas las sedes"
                  disabled={isLoading}
                  onChange={handleToggleAll}
                />

                <div className={css.groupersList}>
                  {groupers?.map(grouper => (
                    <Checkbox
                      key={grouper?.code}
                      className={css.groupersList_checkbox}
                      labelRight={grouper?.name}
                      disabled={isLoading}
                      checked={!!selectedGroupers?.[grouper?.code]}
                      onChange={event => handleToggleGrouper(event.target.checked, grouper)}
                    />
                  ))}
                </div>

                <div className={css.groupersFooter}>
                  <Button
                    className={css.groupersFooter_action}
                    variant="ghost"
                    color="primary"
                    onClick={() => {
                      setSelectedCampusCodes({});
                      setSelectedGroupers({});
                    }}
                  >
                    Desmarcar selección
                  </Button>
                </div>
              </div>
            </div>
          )}

          <ul className={css.card_campuses}>
            {campuses?.map(campus => (
              <li key={campus?.code} className={css.campusItem}>
                <Checkbox
                  className={css.campusItem_checkbox}
                  onChange={toggle(campus?.code)}
                  checked={
                    alreadyCreatedCampusesByCodes?.has(campus?.code) ||
                    has(campus?.code, selectedCampusCodes)
                  }
                  disabled={alreadyCreatedCampusesByCodes.has(campus?.code) || isLoading}
                  labelRight={`${campus?.code} ${campus?.name}`}
                />

                {getCampusGroupersPills(Object.values(selectedGroupers), campus?.code)}
              </li>
            ))}
          </ul>
          <div className={css.card__bottom}>
            <div className={css.bottomCounts}>
              <div className={css.bottomCounts_label}>
                <span className={css.labelCount}>{alreadyCreatedCampusesByCodes?.size ?? 0}</span>{" "}
                Subgrupos existentes
              </div>

              <div className={css.bottomCounts_label}>
                <span className={css.labelCount}>
                  {Object.keys(selectedCampusCodes)?.length ?? 0}
                </span>{" "}
                Sedes seleccionadas
              </div>
            </div>
            <div className={css.card__bottom__buttons}>
              <Button
                disabled={isLoading}
                color="primary"
                variant="outline"
                onClick={() => setDisplayCreation(false)}
              >
                Cancelar
              </Button>
              <Button
                disabled={isEmpty(selectedCampusCodes) || isLoading}
                onClick={async () => {
                  await submitCreations(
                    parentGroup,
                    getCampusIdsFromCampusCodes(campuses, selectedCampusCodes),
                  );
                  requestSubgroups();
                  setDisplayCreation(false);
                }}
              >
                Guardar cambios
              </Button>
            </div>
          </div>
        </Card.Content>
      </Card.Simple>
    </section>
  );
};

export default SubgroupsCreation;
