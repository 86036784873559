import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { Link as Links } from "@models/ISchema";
import { generateTitle } from "../../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { useTranslation } from "react-i18next";
import { DimensionNameEs } from "@models/IDimension";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, loading, error, setPage, pageInfo } = props;
  const route = `/scheduler/editor/link/${workspace}/${scenario}/${origin}`;
  const title = generateTitle(pageInfo?.total || 0, search, "liga");

  const { t } = useTranslation();

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.code")),
      renderCell: ({ id, code }: Links) => getLinkCell(route, id, code),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.campus")),
      renderCell: ({ course }: Links) =>
        getColumnCell(course?.curriculum?.program?.campus?.code || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.course-code")),
      renderCell: ({ course }: Links) => getColumnCell(course?.code || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "165px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.course-name")),
      renderCell: ({ course }: Links) => getColumnCell(course?.name || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "230px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.term")),
      renderCell: ({ bundle }: Links) => getColumnCell(bundle?.term?.code || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.sections-count")),
      renderCell: ({ sectionsCount }: Links) => getColumnCell(sectionsCount ?? "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "160px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.total-blocks")),
      renderCell: ({ totalBlocks }: Links) => getColumnCell(totalBlocks ?? "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "140px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.vacancies")),
      renderCell: ({ vacancies }: Links) => getColumnCell(vacancies ?? "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "124px",
        },
      },
    },
  ];

  return (
    <SearchTable
      isNewTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.link.title") as DimensionNameEs}
      advanceLink={`/editor/advanceSearch/link/${workspace}/${scenario}/${origin}`}
      onChangeSizePerPage={props?.onChangeSizePerPage}
    />
  );
};

export default SearchResults;
