import React, { useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Input, Switch } from "@foris/avocado-ui";
import cx from "classnames";
import { IBundleTerm, IGroup } from "../contextVacancies/ContextVacancies";
import { IParams } from "../../../models/IParams";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import css from "./groups.module.scss";
import GroupStatusIcon from "./GroupStatusIcon/GroupStatusIcon";

export interface IGroupListProps {
  activeEdit: boolean;
  group: IGroup;
  indexGroup: number;
  valueVacancies: string;
  bundleTerm?: IBundleTerm;
  onChange: (e: any) => void;
  onClick: (e: any) => void;
  onClickOpen: (e: any) => void;
  onClickIsActive: (e: any) => void;
  onMigrateGroup?: (groupId: string | number) => void;
}

const GroupList: React.FC<IGroupListProps> = (props: IGroupListProps) => {
  const {
    activeEdit,
    group,
    indexGroup,
    valueVacancies,
    bundleTerm,
    onChange,
    onClick,
    onClickOpen,
    onClickIsActive,
    onMigrateGroup,
  } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const context: IContextApp = useContext(ContextApp);
  const userCanMigrate =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_migrate_groups_in_bundle_view;
  const userCanEdit =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_edit_groups_in_bundle_view &&
    !context?.user?.abilities?.can_access_group_manager &&
    group?.course?.edhCanEdit;
  const userCanDelete =
    context?.user?.permissions?.delete &&
    context?.user?.abilities?.can_delete_groups_in_bundle_view;

  const groupSectionCodes = useMemo(() => {
    const groupSectionsElements = group?.groupSections?.map((section, i) => (
      <span key={section?.id} className={css.itemCode}>{`${section?.code}${
        i < group?.groupSections?.length - 1 ? "," : ""
      }`}</span>
    ));

    return groupSectionsElements?.length ? groupSectionsElements : "-";
  }, [group?.groupSections]);

  return (
    <ul className={css.infoList} key={indexGroup}>
      <li className={cx(css.infoList_item, "container-row", "row_align--center")}>
        {!userCanEdit ? (
          <GroupStatusIcon isActive={group?.isActive} />
        ) : (
          <Switch disabled={!userCanEdit} checked={group?.isActive} onChange={onClickIsActive} />
        )}
      </li>
      <li className={cx(css.infoList_item, "container-row", "row_align--center")}>
        <button
          disabled={!userCanEdit}
          className={cx(
            css.openGroup,
            css.openGroup__eye,
            !group?.openGroup && css.openGroup__closed,
            "container-row",
            "row--center",
            "row_align--center",
          )}
          onClick={e => onClickOpen(e)}
        >
          {group?.openGroup ? (
            <Icon icon="open-eye" size={16} className={css.openGroup_icon} />
          ) : (
            <Icon icon="closed-eye" size={16} className={css.openGroup_icon} />
          )}
        </button>
      </li>
      <li className={css.infoList_item}>
        <Link
          to={`/scheduler/editor/group/${workspace}/${scenario}/${origin}/${group.id}`}
          className={css.link}
        >
          {group.label}{" "}
          {group.clientCode && (
            <>
              <span>|</span> <span className={css.link_regular}>{group.clientCode}</span>
            </>
          )}
        </Link>
      </li>
      <li className={cx(css.infoList_item, css.infoList_item__sectionCodes)}>
        {groupSectionCodes}
      </li>
      <li className={css.infoList_item}>{bundleTerm.code || "-"}</li>
      <li className={cx(css.infoList_item, userCanEdit ? css.infoList_item__input : "")}>
        {!userCanEdit && <span className={css.infoList_vacancies}>{valueVacancies}</span>}
        {group.course.edhCanEdit && userCanEdit && (
          <Input
            classname={{
              input: css.inputItem,
            }}
            value={valueVacancies}
            onChange={e => onChange(e.target.value)}
            disabled={!activeEdit}
          />
        )}
      </li>
      <li className={css.infoList_item}>
        <span>{group.enrollment}</span>
      </li>
      <li className={css.infoList_item}>{group.waitList}</li>
      <li className={css.infoList_item}>
        <span className={css.link_regular}>{group?.campus}</span>
      </li>
      <li className={css.infoList_item}>{group.program}</li>
      <li className={css.infoList_item}>{group.curriculum}</li>
      <li className={css.infoList_item}>{group.course ? group.course.code : "-"}</li>
      {/* ICONO  */}
      <li className={cx(css.infoList_item, "container-row", "row--right", "row_align--center")}>
        {userCanMigrate && group?.course?.edhCanEdit && (
          <button
            className={cx(
              css.disabledGroupBtn,
              css.migrateGroupBtn,
              "container-row",
              "row--end",
              "row_align--center",
            )}
            onClick={() => onMigrateGroup?.(group?.id)}
          >
            <Icon icon="arrow-right" size={14} className={css.disabledGroupBtn_icon} />
          </button>
        )}
      </li>
      <li className={cx(css.infoList_item, "container-row", "row--right", "row_align--center")}>
        {group.course.edhCanEdit && userCanDelete && (
          <button
            className={cx(css.disabledGroupBtn, "container-row", "row--end", "row_align--center")}
            onClick={e => onClick(e)}
            disabled={group && group.enrollment && group.enrollment > 0}
          >
            <Icon icon="minus" size={14} className={css.disabledGroupBtn_icon} />
          </button>
        )}
      </li>
    </ul>
  );
};

export default GroupList;
