import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Link, Tag } from "@foris/avocado-suite";
import { groupBy, orderBy } from "lodash";
import { ContextEditLabel, ILabel } from "./ContextEditLabels";
import css from "./listLabels.module.scss";

interface ITagGroup {
  title: string;
  isSystem?: boolean;
  value: ILabel[];
}

const ListLabels: React.FC = () => {
  const context = useContext(ContextEditLabel);
  const { t } = useTranslation();
  const groups = groupBy(context.labels, value => value.category.name);
  const orderGroups = useMemo(() => {
    const groupsMap: ITagGroup[] = [];

    for (const [key, value] of Object.entries(groups)) {
      const objectLabel: ITagGroup = {
        title: key,
        isSystem: value[0]?.category?.labelSource?.code !== "USER-DEFINED",
        value,
      };
      groupsMap.push(objectLabel);
    }

    return orderBy(groupsMap, ["title"], ["asc"]);
  }, [groups]);

  const userDefinedGroups = useMemo(() => {
    return orderGroups.filter(value => value.isSystem === false);
  }, [orderGroups]);

  const deleteLabels = (label: ILabel | ILabel[]) => {
    const { labels, options } = context;
    const cloneLabels = labels ? [...labels] : [];
    const cloneOptions = options ? [...options] : [];

    const removeLabel = (tag: ILabel) => {
      const find = cloneLabels.findIndex(value => value.id === tag.id);
      cloneLabels.splice(find, 1);
    };

    const addOption = (tag: ILabel) => {
      const option = {
        label: `${tag?.code} | ${tag?.name} | ${tag?.category?.name}`,
        value: tag,
      };
      cloneOptions.push(option);
    };

    if (Array.isArray(label)) {
      label.forEach(tag => {
        removeLabel(tag);
        addOption(tag);
      });
    } else {
      removeLabel(label);
      addOption(label);
    }

    context.setLabels(cloneLabels);
    context.setOptions(cloneOptions);
  };

  const onDeleteCategory = (category: string) => {
    const deleteTags = orderGroups.filter(value => value.title === category);
    deleteTags.forEach(group => deleteLabels(group.value));
  };

  return (
    <>
      {userDefinedGroups &&
        userDefinedGroups.map((value, index) => (
          <Box key={index} className={css.listLabels}>
            <div className={css.listLabels_header}>
              <Heading type="h3">{value.title}</Heading>
              <Link size="sm" iconLeft="circle-minus" onClick={() => onDeleteCategory(value.title)}>
                {t("edit-tag-manually.delete-category")}
              </Link>
            </div>
            <div className={css.listLabels_contentTags}>
              {value.value &&
                value.value.map((label, index) => (
                  <Tag
                    key={`label_${label?.code}_${index}`}
                    onIconClick={() => deleteLabels(label)}
                    icon="close"
                    active={label?.isNew}
                  >
                    {label.code} | {label.name}
                  </Tag>
                ))}
            </div>
          </Box>
        ))}
    </>
  );
};

export default ListLabels;
