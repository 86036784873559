import React from "react";
import { SkeletonBase } from "@foris/avocado-suite";
import cx from "classnames";
import styles from "./autocomplete.module.scss";

export interface IOption {
  label: string | JSX.Element;
  value: string;
}

interface IOptions {
  options: Array<IOption>;
  onSelect?: (value: IOption) => void;
  loading?: boolean;
}

const Options: React.FC<IOptions> = (props: IOptions) => {
  const { options, onSelect, loading } = props;

  return (
    <ul className={styles.listSuggest}>
      {loading ? (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <li key={`skeleton-${index}`}>
              <SkeletonBase height={34} width="100%" radius={8} />
            </li>
          ))}
          <li className={cx(styles.listSuggest_item, styles.listSuggest_item__separator)} />
          <li>
            <SkeletonBase height={34} width="100%" radius={8} />
          </li>
        </>
      ) : (
        <>
          {options.length > 0 &&
            options.map((value: IOption, i) => (
              <>
                {value?.value === "all" && (
                  <li
                    key={`option-separator-${i}`}
                    className={cx(styles.listSuggest_item, styles.listSuggest_item__separator)}
                  />
                )}
                <li
                  key={`option-${i}-${value.value}`}
                  className={cx(
                    styles.listSuggest_item,
                    value?.value === "empty" && styles.listSuggest_item__empty,
                  )}
                  onClick={() => value?.value !== "empty" && onSelect?.(value)}
                >
                  {typeof value.label === "string" ? (
                    <p className={styles.textSuggest}>{value.label}</p>
                  ) : (
                    value.label
                  )}
                </li>
              </>
            ))}
        </>
      )}
    </ul>
  );
};

export default Options;
