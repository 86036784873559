import React, { useContext } from "react";
import cx from "classnames";
import { Icon } from "@foris/avocado-ui";
import { ContextEDH } from "@context/ContextEDH";
import { IGroupValue } from "../contextVacancies/ContextVacancies";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import * as mouseflow from "@utils/mouseflow";
import css from "./groups.module.scss";

export interface ITotalListProps {
  totalEnrollments: any;
  totalWaitList: any;
  countVacancies: Array<IGroupValue>;
  onClick: () => void;
  indexList: number;
  groupsCount?: number;
  edhCanEdit?: boolean;
}

const TotalList: React.FC<ITotalListProps> = (props: ITotalListProps) => {
  const {
    totalEnrollments,
    totalWaitList,
    onClick,
    indexList,
    countVacancies,
    groupsCount,
    edhCanEdit,
  } = props;
  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);

  const canAddMoreGroups = () => {
    if (state?.base?.groupsInLinkRestriction?.enabled) {
      return groupsCount < state?.base?.groupsInLinkRestriction?.groups_allowed;
    }

    return true;
  };
  const canAddGroup =
    edhCanEdit &&
    context?.user?.permissions?.create &&
    context?.user?.abilities?.can_create_groups_in_bundle_view &&
    canAddMoreGroups();

  const totalLeague = (param: any) => {
    let total = 0;
    if (countVacancies)
      total = param.reduce((elementA: any, elementB: any) => elementA + elementB, 0);
    return total;
  };

  const vacanciesTotal = () => {
    let total = 0;
    if (countVacancies) countVacancies.map((group: IGroupValue) => (total = total + group.value));
    return total;
  };

  return (
    <ul className={css.totalList} key={`total-${indexList}`}>
      <li className={css.totalList_item}>TOTAL</li>
      <li className={cx(css.totalList_item, css.totalWrapper)}>
        <span className={css.totalInput}>{vacanciesTotal()}</span>
      </li>
      <li className={css.totalList_item}>{totalLeague(totalEnrollments)}</li>
      <li className={css.totalList_item}>{totalLeague(totalWaitList)}</li>
      {/* ICONO  */}
      <li
        className={cx(
          css.totalList_item,
          css.actionWrapper,
          "container-row",
          "row--right",
          "row_align--center",
        )}
      >
        {canAddGroup && (
          <button
            className={cx(css.addGroup, "container-row", "row--center", "row_align--center")}
            onClick={() => {
              mouseflow.actionTag("action_add_group", state?.base?.isMouseflowEnabled);
              onClick && onClick();
            }}
          >
            <Icon icon="plus" size={14} className={css.addGroup_icon} />
          </button>
        )}
      </li>
    </ul>
  );
};

export default TotalList;
