import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import queryString from "query-string";
import { IParams } from "../../../../models/IParams";
import { megaSearch } from "../graphql/courseMegaSearch.query";
import { courseSearchQuery } from "../graphql/advanceSearch.queries";
import SearchResults from "./SearchResults";
import Layout from "../../../../common/layout/Layout";
import { CoursePage } from "../../../../models/ISchema";
import { variablesSearch } from "../../utils/searchDimension";

const SearchCourse: React.FC = () => {
  const { origin, scenario }: IParams = useParams();
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [page, setPageCount] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const variables = variablesSearch(scenario, origin, params, page, undefined, sizePerPage);
  const querySearch = params?.advance ? courseSearchQuery : megaSearch;

  const { loading, error, data } = useQuery(querySearch, { variables });
  let courses: CoursePage = data?.cube?.coursesWithSections;
  if (params?.advance) {
    courses = data?.filter?.courses;
  }

  return (
    <Layout contextSearch>
      <SearchResults
        data={courses?.items}
        pageInfo={courses?.pageInfo}
        search={params?.search?.toString()}
        setPage={(value: number) => setPageCount(value)}
        loading={loading}
        error={error}
        onChangeSizePerPage={setSizePerPage}
      />
    </Layout>
  );
};

export default SearchCourse;
