import React, { FC } from "react";
import { SkeletonBase } from "@foris/avocado-suite";
import css from "./searchTable.module.scss";

interface SearchTableSkeletonProps {
  rows?: number;
  columns?: number;
  loading?: boolean;
}

const SearchTableSkeleton: FC<SearchTableSkeletonProps> = ({ columns = 6, rows = 13 }) => {
  return (
    <div className={css.cntResultsSkeleton}>
      <div className={css.cntResultsSkeleton_header}>
        <SkeletonBase maxWidth={182} height={36} radius={4} />
      </div>
      <div className={css.cntResultsSkeleton_table}>
        <div className={css.header}>
          <SkeletonBase height={38} />
        </div>

        {Array.from({ length: rows }).map((_, _index) => (
          <div className={css.columns} key={_index}>
            {Array.from({ length: columns }).map((_, _indexItem) => (
              <div className={css.columns_item} key={_indexItem}>
                <SkeletonBase width={"100%"} height={28} />
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={css.cntResultsSkeleton_paginationBtn}>
        <SkeletonBase maxWidth={36} height={36} radius={"8px 0 0 8px"} />
        {Array(11)
          .fill([])
          .map((_, index) => {
            return <SkeletonBase key={index} maxWidth={36} height={36} radius={0} />;
          })}
        <SkeletonBase maxWidth={36} height={36} radius={"0 8px 8px 0"} />
      </div>
    </div>
  );
};

export default SearchTableSkeleton;
