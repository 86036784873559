import React, { useContext, useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import cx from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { CardState, Table } from "@foris/avocado-ui";
import WeekColumn from "./WeekColumn";
import { AppContext } from "../../context/EditSessionsContext";
import {
  EditorValidationError,
  IntervalValidationErrors,
  StudentClash,
  CustomValidation,
  SessionPayload,
  InvalidSessionInHoliday,
} from "@models/ISchema";
import IncidenceByType from "@models/Incidences/SessionByType";
import IncidenceByCode from "@models/Incidences/SessionByCode";
import css from "./incidenceTable.module.scss";
import { Week } from "@modules/sections/ISections";

const getWeek = (weeks: Week[] = [], date = ""): Week => {
  return weeks?.find(week => date >= week?.startingDate && date <= week?.endingDate);
};

const IncidenceTable: React.FC = () => {
  const { state } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState<EditorValidationError[]>([]);
  const [intervalErrors, setIntervalErrors] = useState<IntervalValidationErrors[]>([]);
  const [customValidations, setCustomValidations] = useState<CustomValidation[]>([]);
  const selected = state?.result?.selectSession;
  const createdSessionSelected = state?.result?.selectCreateSession;
  const sessionToDeleteSelected = state?.result?.sessionToDeleteSelected;
  const arrayValidations: (
    | EditorValidationError
    | IntervalValidationErrors
    | CustomValidation
  )[] = useMemo(() => {
    const validations = {
      holidayErrors: [],
      validationErrors: [],
    };

    validationErrors?.forEach(error => {
      if (error.__typename === "InvalidSessionInHoliday") {
        const holidayErr = error as InvalidSessionInHoliday;

        holidayErr.holiday.forEach(holiday => {
          validations.holidayErrors.push({
            ...holidayErr,
            holiday,
          });
        });
      } else {
        validations.validationErrors.push(error);
      }
    });

    return [
      ...intervalErrors,
      ...validations?.holidayErrors,
      ...validations?.validationErrors,
      ...customValidations,
    ];
  }, [intervalErrors, validationErrors, customValidations]);

  const tableValidations = useMemo(() => {
    const intervalValidationsObj = {};
    const regularValidations = [];

    arrayValidations.forEach(validation => {
      if (validation.__typename === "IntervalValidationErrors") {
        const intervalId = validation.interval.id;

        if (intervalId in intervalValidationsObj) {
          intervalValidationsObj[intervalId]?.data?.validationErrors.push(validation);
        } else {
          intervalValidationsObj[intervalId] = {
            id: intervalId,
            data: validation,
          };
        }
      } else if (validation?.__typename === "InvalidPastSessionEdition") {
        validation?.intervals?.forEach(interval => {
          const intervalId = interval.id;

          if (intervalId in intervalValidationsObj) {
            const errorObj = intervalValidationsObj[intervalId]?.data as IntervalValidationErrors;
            const hasErrorSaved = (errorObj?.validationErrors ?? [])?.find(
              err => err.__typename === "InvalidPastSessionEdition",
            );

            !hasErrorSaved &&
              intervalValidationsObj[intervalId]?.data?.validationErrors.push(validation);
          } else {
            intervalValidationsObj[intervalId] = {
              id: intervalId,
              data: {
                interval,
                validationErrors: [validation],
                __typename: "IntervalValidationErrors",
              },
            };
          }
        });
      } else {
        regularValidations.push({
          id: "0",
          data: validation,
        });
      }
    });

    return [...regularValidations, ...Object.values(intervalValidationsObj)];
  }, [arrayValidations]);

  const anyErrorExists = () => {
    const hasError = (sessionPayload: SessionPayload): boolean =>
      R.any(R.pipe(R.length, R.gt(R.__, 0)))([
        sessionPayload.customValidations,
        sessionPayload.intervalValidationErrors,
        sessionPayload.validationErrors,
      ]);

    const creationValidationErrors = R.reduce(
      (acc, sessionsPayload) => R.or(acc, hasError(sessionsPayload)),
      false,
      R.view(R.lensPath(["result", "createValidation", "sessionsPayload"]), state) ?? [],
    );
    const editionValidationErrors = R.reduce(
      (acc, sessionsPayload) => R.or(acc, hasError(sessionsPayload)),
      false,
      R.view(R.lensPath(["result", "resultValidation", "sessionsPayload"]), state) ?? [],
    );

    return R.or(creationValidationErrors, editionValidationErrors);
  };

  const weekColumn = (data: any) => {
    const typename = data?.cell?.row?.original?.data?.__typename;
    const value: EditorValidationError | IntervalValidationErrors | StudentClash =
      data?.cell?.row?.original?.data;
    const errorWeek =
      typename === "InvalidSessionInHoliday"
        ? getWeek(state?.link?.weeks, data?.cell?.row?.original?.data?.holiday?.date)
        : undefined;

    return <WeekColumn column={value} week={errorWeek} />;
  };

  const sortIncidence = (a: any, b: any) => {
    return parseInt(a.id) - parseInt(b.id);
  };

  const incidenceByInterval = (validationRow: IntervalValidationErrors) => {
    let validationErrors = [];
    if (validationRow.validationErrors) validationErrors = validationRow.validationErrors;

    if (validationErrors.length === 0) {
      return (
        <div className="container-row row--wrap">
          <span className={css.incidenceValidation}>Semana sin incidencias</span>
        </div>
      );
    }

    return (
      <>
        {validationErrors.map((incidence: any, indexVal: number) => {
          const errorType = new IncidenceByType({ incidence });
          const incidences = incidence.sessions || [];

          if (!incidences?.length) {
            return (
              <div className={cx(css.validationRow, "container-row", "row--wrap")} key={indexVal}>
                <span className={css.incidenceValidation}>{errorType.getMessage()}</span>
              </div>
            );
          }

          return incidences?.map((session: any, index: number) => {
            if (session?.id !== selected?.id) {
              return (
                <div
                  className={cx(css.incidenceValidation, "container-row", "row--wrap")}
                  key={index}
                >
                  <span>{errorType.getMessage()}</span>
                  <span className={css.incidenceValidation_session}>
                    {session?.section ? ` Sección ${session?.section.id} - ` : ""} Sesión{" "}
                    {session?.id}
                  </span>
                </div>
              );
            }
            return null;
          });
        })}
      </>
    );
  };

  const incidenceValidation = (data: any) => {
    const value = data?.cell?.row?.original?.data;
    const errorType = new IncidenceByType({ incidence: value });

    switch (value.__typename) {
      case "IntervalValidationErrors":
        return incidenceByInterval(value);
      case "CustomValidation":
        const errorCode = new IncidenceByCode(value);
        return errorCode.getMessage();
      case "InvalidSessionInHoliday":
        return (
          <div className={css.weekHoliday}>
            <span className={css.weekHoliday_title}>Día festivo:</span>
            {errorType.getMessage()}
          </div>
        );

      default:
        return errorType.getMessage();
    }
  };

  const columns = [
    {
      id: "weeks",
      Header: "Semana",
      maxWidth: 400,
      Cell: weekColumn,
    },
    {
      id: "incidence",
      Header: "Incidencia",
      Cell: incidenceValidation,
    },
  ];

  useEffect(() => {
    if (selected || sessionToDeleteSelected) {
      const result = state?.result?.resultValidation;
      if (!result?.commited) {
        const innerSelected = selected || sessionToDeleteSelected;
        const sessionPayload = R.find(
          res => res.sessionId === innerSelected.id,
          result?.sessionsPayload,
        );
        setValidationErrors(sessionPayload?.validationErrors ?? []);
        setIntervalErrors(sessionPayload?.intervalValidationErrors ?? []);
        setCustomValidations(sessionPayload?.customValidations ?? []);
      }
    }

    if (createdSessionSelected) {
      const result = state?.result?.createValidation;
      const sessionPayload = R.find(
        res => res?.sessionUuid === createdSessionSelected?.id,
        result?.sessionsPayload,
      );
      setValidationErrors(sessionPayload?.validationErrors ?? []);
      setIntervalErrors(sessionPayload?.intervalValidationErrors ?? []);
      setCustomValidations(sessionPayload?.customValidations ?? []);
    }
  }, [state?.result]);

  if (!selected && !createdSessionSelected && !sessionToDeleteSelected && anyErrorExists()) {
    return (
      <CardState typeCard="warning" title="Validaciones fallidas" className={css.successCard}>
        Alguna asignación no cumple con todas las validaciones.
      </CardState>
    );
  } else if ((sessionToDeleteSelected || tableValidations.length === 0) && !anyErrorExists()) {
    return (
      <CardState typeCard="confirm" title="Validaciones exitosas" className={css.successCard}>
        {createdSessionSelected
          ? `La nueva sesión cumple con todas las validaciones. Es seguro realizar la asignación.`
          : `Las modificaciones de asignación cumplen con todas las validaciones. Es seguro realizar el cambio.`}
      </CardState>
    );
  }

  return (
    <Tabs className={css.tabs}>
      <TabList>
        <Tab>Incidencias</Tab>
      </TabList>
      <TabPanel>
        <Table.Container
          className={css.table}
          data={tableValidations.sort(sortIncidence)}
          columns={columns}
        />
      </TabPanel>
    </Tabs>
  );
};

export default IncidenceTable;
