import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PackageFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packages(filter: $filter) {
        items {
          id
          vacancies
          index
          links {
            id
            groups {
              id
            }
          }
          population {
            id
            code
            level
            curriculum
            term {
              id
              name
              code
            }
            campus {
              id
              code
            }
            modality {
              id
              code
            }
            shift {
              id
              code
            }
            program {
              id
              code
              name
            }
            courses {
              id
              curriculum {
                id
                code
                program {
                  id
                  code
                  campus {
                    id
                    code
                  }
                  modality {
                    id
                    code
                  }
                  shift {
                    id
                    code
                  }
                }
              }
            }
          }
          code
          __typename
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const shortMegaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PackageFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packages(filter: $filter) {
        items {
          id
          vacancies
          index
          code
          name
          labels {
            id
            code
            name
            category {
              id
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
