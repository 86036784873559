import React, { useState } from "react";
import cx from "classnames";
import { IOption } from "../../../../../common/components/Select/Select";
import { RadioButton } from "@foris/avocado-suite";
import SelectSection from "./SelectSection";
import { CourseComponent } from "../../../../../models/ISchema";
import css from "./addLinkSection.module.scss";

export type CreateType = "NEW_SECTION" | "OLD_SECTION";

export interface IReturnComponent {
  courseComponent: CourseComponent;
  typeSection: CreateType;
  section: IOption;
}

interface IComponentItem {
  courseId: number;
  courseComponent: CourseComponent;
  onCallback: (value: IReturnComponent) => void;
}

const ComponentItem: React.FC<IComponentItem> = (props: IComponentItem) => {
  const { courseComponent, courseId, onCallback } = props;
  const [typeSection, setTypeSection] = useState<CreateType>("NEW_SECTION");

  return (
    <li className={cx(css.listComponent_item, "container-row")}>
      <div className={cx(css.componentType, "container-row")}>
        <p className={css.componentType_title}>{courseComponent.component.name}</p>
        <div className={cx("container-row", css.componentType_options)}>
          <RadioButton
            className={css.componentType_option}
            labelRight="Crear una sección nueva"
            checked={typeSection == "NEW_SECTION"}
            onChange={() => {
              setTypeSection("NEW_SECTION");
              const returnValue: IReturnComponent = {
                courseComponent,
                typeSection: "NEW_SECTION",
                section: null,
              };
              onCallback(returnValue);
            }}
          />
          <RadioButton
            className={css.componentType_option}
            labelRight="Ligar con sección existente"
            checked={typeSection == "OLD_SECTION"}
            onChange={() => {
              setTypeSection("OLD_SECTION");
              const returnValue: IReturnComponent = {
                courseComponent,
                typeSection: "OLD_SECTION",
                section: null,
              };
              onCallback(returnValue);
            }}
          />
        </div>

        {typeSection === "OLD_SECTION" && (
          <div className="container-row">
            <SelectSection
              className={cx(css.componentType_select, "col_6")}
              courseId={courseId}
              courseComponentId={courseComponent.id}
              onChange={(value: IOption) => {
                const returnValue: IReturnComponent = {
                  courseComponent,
                  typeSection,
                  section: value,
                };
                onCallback(returnValue);
              }}
            />
          </div>
        )}
      </div>
    </li>
  );
};

export default ComponentItem;
