import React from "react";
import { useTranslation } from "react-i18next";
import { Pill } from "@foris/avocado-suite";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Group } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getOpenGroupCell,
  getLabelCell,
} from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";
import css from "../../../dimensions/utils/tableUtils.module.scss";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, error, loading, setPage, onChangeSizePerPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "grupo");
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const routeGroup = `/scheduler/editor/group/${contextUrl}`;
  const routeBundle = `/editor/vacancies/${contextUrl}`;
  const routeLink = `/scheduler/editor/link/${contextUrl}`;
  const { t } = useTranslation();

  const capacityCell = (group: Group) => {
    const capacity = group?.capacity;
    const enrollmentsCount = group?.enrollmentStats?.enrollmentsCount
      ? group.enrollmentStats?.enrollmentsCount
      : "sin inscritos";
    const waitingListsCount = group?.enrollmentStats?.waitingListsCount
      ? group.enrollmentStats?.waitingListsCount
      : "";

    return getColumnCell(
      `${capacity} | ${enrollmentsCount}  ${waitingListsCount && `(+${waitingListsCount})`}`,
    );
  };

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.group")),
      renderCell: ({ id, code, name }: Group) => getLinkCell(routeGroup, id, `${code} ${name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "210px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.campus")),
      renderCell: ({ course }: Group) => getColumnCell(course?.curriculum?.program?.campus?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.period")),
      renderCell: ({ term }: Group) => getColumnCell(term?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.bundle")),
      renderCell: ({ links, id }: Group) =>
        getLinkCell(routeBundle, id, `${links?.[0]?.bundle?.code}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.league")),
      renderCell: ({ id, links }: Group) => getLinkCell(routeLink, id, `${links?.[0]?.code}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.quota-enrolled")),
      renderCell: capacityCell,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "190px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.labels")),
      renderCell: (group: Group) => getLabelCell(group?.labels),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "145px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.status.title")),
      renderCell: (group: Group) => (
        <Pill className={css.columnPill}>
          {group?.isActive
            ? t("search-results.dimension.group.table.header.status.active")
            : t("search-results.dimension.group.table.header.status.inactive")}
        </Pill>
      ),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.visibility")),
      renderCell: (group: Group) => getOpenGroupCell(group.visibleForEnrollment),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "120px" },
      },
    },
  ];

  return (
    <SearchTable
      onChangeSizePerPage={onChangeSizePerPage}
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      isNewTable
      dimension={t("dimension.group.title") as DimensionNameEs}
      advanceLink={`/editor/advanceSearch/group/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
