import { Config } from "./types";
import { days, formatHoursDay, formatMinutesDay } from "./formats";
import { EVENT_STATES } from "./enums";
import { IEvent, ICalendarRestriction, IWeek, IUnassigned } from "../ISections";
import { ClassroomBooking, EditorView } from "../../../models/ISchema";
import { cloneDeep, groupBy as _groupBy } from "lodash";

interface IEventFilter {
  type: string;
  filterCriteria: string;
  values: Array<{ id: string; label: string }>;
}

interface IEventFilterTmp {
  id: string;
  label: string;
  styles: { categoryID: number };
}

const serializeSections = (data: any) => {
  const events: Array<IEvent> = [];
  const eventFilters: Array<IEventFilter> = [];
  const weeks: Array<IWeek> = [];
  const tmpFilterArray: Array<IEventFilterTmp> = [];
  const unassigned: Array<IUnassigned> = [];
  const editorView: EditorView = data.cube.editorView;

  editorView.sections.forEach((section, index: number) => {
    let courseName = "";
    if (section.sessions[0])
      courseName = `${section.id} ${section.sessions[0].section.course.name}`;

    tmpFilterArray.push({
      id: section.id,
      label: courseName,
      // label: `${section.id} ${section.sessions[0].section.course.name}`,
      styles: { categoryID: index },
    });

    // Sessions Map
    section.sessions.forEach(session => {
      session.scheduleEvent.forEach(block => {
        //Add Events
        events.push({
          type: editorView.info.__typename,
          colorIndex: block.colorIndex,
          sessionId: parseInt(session.id),
          sectionId: parseInt(section.id),
          styles: {
            categoryID: index,
          },
          id: block.id,
          title: `${session.section.course.code} ${session.section.course.name}`,
          canEditCourse: session.section.course.edhCanEdit,
          start: new Date(block.start),
          end: new Date(block.end),
          blocksCount: session.blocksCount,
          resource: {
            ...session.assignment,
            blocksCount: session.blocksCount,
            instructors: session.instructors,
            classrooms: session.classrooms,
            info: {
              section: {
                id: section.id,
                code: session.section.code,
                vacancies: session.section.vacancies,
                groups: session.section.groups,
                usedCapacity: session.section.usedCapacity,
                waitingLists: session.section.waitingListsCount,
              },
              course: session.section.course,
              courseComponent: session.section.courseComponent,
            },
          },
          prevResource: cloneDeep(session.assignment),
          error: {
            status: false,
            details: null,
          },
          state: EVENT_STATES.UNMODIFIED,
          status: false,
          stripeProperties: session.section.courseComponent.stripePriorities,
          bundle: session.section.bundle,
          links: session.section.links,
        });
      });
    });
    section.unasignedSessions.forEach(session => {
      //Add unassigned session
      unassigned.push({
        type: editorView.info.__typename,
        colorIndex: 9999,
        sessionId: parseInt(session.id),
        sectionId: parseInt(section.id),
        styles: {
          categoryID: index,
        },
        name: session.section.course.name,
        id: "",
        title: `${session.section.course.code} ${session.section.course.name}`,
        canEditCourse: session.section.course.edhCanEdit,
        start: new Date(""),
        end: new Date(""),
        blocksCount: session.blocksCount,
        resource: {
          ...session.assignment,
          blocksCount: session.blocksCount,
          instructors: session.instructors,
          classrooms: session.classrooms,
          info: {
            section: {
              id: section.id,
              code: session.section.code,
              vacancies: session.section.vacancies,
              groups: session.section.groups,
              usedCapacity: session.section.usedCapacity,
              waitingLists: session.section.waitingListsCount,
            },
            course: session.section.course,
            courseComponent: session.section.courseComponent,
          },
        },
        prevResource: cloneDeep(session.assignment),
        error: {
          status: false,
          details: null,
        },
        state: EVENT_STATES.UNMODIFIED,
        stripeProperties: session.section.courseComponent.stripePriorities,
        status: session.section.courseComponent.online,
        bundle: session.section.bundle,
        links: session.section.links,
      });
    });
  });

  editorView.bookings?.forEach((classroomBooking: ClassroomBooking, index: number) => {
    // Sessions Map
    classroomBooking.sessions.forEach(session => {
      session.scheduleEvent.forEach(block => {
        //Add Events
        events.push({
          ...events,
          type: editorView.info.__typename,
          bookings: {
            id: classroomBooking.id,
            classroom: classroomBooking.classroom,
            origin: classroomBooking.origin,
            title: classroomBooking.title,
            description: classroomBooking.description,
            status: classroomBooking.status,
            capacity: classroomBooking.capacity,
            responsible: classroomBooking.responsible,
            sessions: classroomBooking.sessions,
            type: classroomBooking.type,
            createdAt: classroomBooking.createdAt,
          },
          resource: {
            ...session.assignment,
            blocksCount: session.blocksCount,
            instructors: session.instructors,
            classrooms: session.classrooms,
          },
          colorIndex: block.colorIndex,
          sessionId: parseInt(session.id),
          styles: {
            categoryID: index,
          },
          id: classroomBooking.id,
          title: `${classroomBooking.title}`,
          start: new Date(block.start),
          end: new Date(block.end),
          error: {
            status: false,
            details: null,
          },
          state: EVENT_STATES.UNMODIFIED,
        });
      });
    });
  });

  eventFilters.push({ type: "SECTION", filterCriteria: "sectionId", values: tmpFilterArray });

  data.cube.editorWeeks.forEach((item: any, index: number) => {
    weeks.push({
      id: item.id,
      value: (index + 1).toString(),
      status: parseInt(item.eventCount) ? "on" : "off",
      title: `${item.name}: ${item.startingDate}/${item.endingDate}`,
      endingDate: new Date(`${item.endingDate} 00:00:00`),
      startingDate: new Date(`${item.startingDate} 00:00:00`),
      eventCount: item.eventCount,
      termParts: item.termParts,
    });
  });

  const initialSelectedWeek = weeks[weeks.findIndex((week: IWeek) => week.status === "on")];

  const restrictionsByDay: any = [];

  if (data.cube.editorRestrictions) {
    data.cube.editorRestrictions.forEach((item: any) => {
      const tmp: Array<ICalendarRestriction> = [];
      let restLabel = "";

      item.scheduleEvent.forEach((r: any) => {
        tmp.push({
          id: r.id,
          dayId: r.dayId,
          start: r.start,
          end: r.end,
          defaultChecked: true,
        });
      });

      if (item.type === "INSTRUCTOR_AVAILABILITY") {
        restLabel = "Disponibilidad docente";
      }

      restrictionsByDay.push({
        type: item.type,
        label: restLabel,
        scheduleEvent: _groupBy(tmp, "dayId"),
      });
    });
  }
  return {
    calendarRestrictions: restrictionsByDay,
    weekList: weeks,
    selectedWeek: initialSelectedWeek,
    eventFilters,
    eventList: events,
    unassigned: unassigned,
  };
};

const serializeSectionMutation = (
  config: Config,
  sessionId: number,
  startTime: Date,
  endTime: Date,
) => {
  const params = {
    originId: config.originId,
    scenarioId: config.scenarioId,
    input: {
      sessionId: sessionId,
      dryRun: false,
      skipValidations: false,
      clientMutationId: "mutation",
      changeset: {
        changeBlocks: {
          op: "CHANGE",
          day: days[startTime.getDay()].toUpperCase(),
          startTime: `${formatHoursDay(startTime)}:${formatMinutesDay(startTime)}:00`,
          endTime: `${formatHoursDay(endTime)}:${formatMinutesDay(endTime)}:00`,
        },
      },
    },
  };

  return params;
};

export { serializeSections, serializeSectionMutation };
