import React from "react";
import cx from "classnames";
import styles from "./dropFilter.module.scss";

export interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  options: Array<IOption>;
  className?: string;
  onAnimationEnd?: () => void;
  onSelect: (value: IOption) => void;
}

const Options: React.FC<IOptions> = (props: IOptions) => {
  const { options, className, onAnimationEnd, onSelect } = props;
  if (options.length === 0) {
    return null;
  }
  return (
    <ul
      className={cx(styles.dropFilterOptions, className && className)}
      onAnimationEnd={onAnimationEnd}
    >
      {options.map((value: IOption) => (
        <li
          key={value.value}
          className={styles.dropFilterOptions_item}
          onClick={() => onSelect(value)}
        >
          <p className={styles.textSelect}>{value.label}</p>
        </li>
      ))}
    </ul>
  );
};

export default Options;
