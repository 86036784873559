import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "@foris/avocado-icons";
import { Input } from "@foris/avocado-ui";
import GroupCodeInput from "@modules/vacancies/Groups/group-code-input";
import styles from "./addGroupSection.module.scss";

interface AddGroupSectionProps {
  vacancies?: string | number;
  externalErrors?: {
    type?: string;
    text: string;
  }[];
  onValidForm?: (isValid: boolean) => void;
  onStateChange?: (state: { groupCode: string | number; groupVacancies: string | number }) => void;
}

const AddGroupSection = ({
  vacancies = "",
  externalErrors = [],
  onValidForm,
  onStateChange,
}: AddGroupSectionProps) => {
  const [groupVacancies, setGroupVacancies] = useState<number | string>(vacancies);
  const [groupCode, setGroupCode] = useState("");
  const [groupCodeErrors, setGroupCodeErrors] = useState([]);

  const errors = useMemo(() => [...externalErrors, ...groupCodeErrors], [
    externalErrors,
    groupCodeErrors,
  ]);

  useEffect(() => {
    onValidForm?.(!groupCodeErrors?.length && !!groupCode && !!groupVacancies);
  }, [groupCodeErrors, groupCode, groupVacancies]);

  useEffect(() => {
    onStateChange?.({
      groupCode,
      groupVacancies,
    });
  }, [groupCode, groupVacancies]);

  return (
    <div className={styles.groupForm}>
      <h3 className={styles.groupForm_title}>Paso 3: Configuración del grupo</h3>

      <div className={styles.groupForm_fields}>
        <div className={styles.formField}>
          <GroupCodeInput
            className={styles.formField_field}
            onChange={setGroupCode}
            onError={setGroupCodeErrors}
          />

          {errors?.length > 0 && (
            <ul className={styles.formErrors}>
              {errors.map((error, index) => (
                <li key={index} className={styles.formErrors_error}>
                  <Icon filled name="circle-cross" color="error-50" size="sm" />
                  {error?.text ?? error?.message}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={styles.formField}>
          <Input
            classname={{
              global: styles.formField_field,
            }}
            label="Vacantes"
            placeholder="Número vacantes"
            type="number"
            value={groupVacancies}
            onChange={event => {
              setGroupVacancies(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddGroupSection;
