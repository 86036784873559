import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Loading } from "@foris/avocado-ui";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, Classroom, EditorInfoUnion } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { classroomSection } from "../graphql/classroomSection.query";
import { Dimension } from "@models/IDimension";
import useGetDimension from "@dimensions/hooks/useGetDimension";

const ClassRoomCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const { t } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.classroom);

  const variables = {
    query: classroomSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "CLASSROOM",
    },
  };

  const headerInfo = (infoRoom: Classroom) => {
    const details = [
      {
        title: "Piso",
        content: infoRoom?.floor || "",
      },
      {
        title: "Capacidad",
        content: infoRoom?.capacity?.toString() || "",
      },
      {
        title: "Tipo",
        content: infoRoom?.classroomType?.name || "",
      },
      {
        title: "Sobrecarga",
        content: infoRoom?.allowedOverflow?.toString() || "",
      },
      {
        title: "Usable",
        content: infoRoom?.usable ? "Si" : "No",
      },
      {
        title: "Sede",
        content: infoRoom?.building?.campus?.name,
      },
    ];

    return {
      title: `${infoRoom?.name || ""} (${infoRoom.code || ""})`,
      topTitle: infoRoom?.building?.name || "",
      moreDetails: details,
    };
  };

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const info: EditorInfoUnion = cubeQuery.editorView.info;
        if (info.__typename === "ClassroomInfo") {
          const classroomInfo: Classroom = info?.classroom;
          if (classroomInfo) {
            const header = headerInfo(classroomInfo);
            setHeaderData(header);
            const sections: ISectionData = functionsData.serializeSections(data);
            setCalendar(sections);
          } else {
            setError(true);
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <Layout contextSearch>
      {headerData && (
        <Header
          dimension={dimension.label}
          breadcrumbItems={[
            {
              title: t("dimension.classroom.breadcrumb.previous"),
              path: "/",
            },
            {
              title: t("dimension.classroom.breadcrumb.current"),
            },
          ]}
          {...headerData}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension="Sala" />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </Layout>
  );
};

export default ClassRoomCalendar;
