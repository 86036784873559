import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { ErrorTpe, IErrorWarning } from "../../../../../models/Errors/ErrorWarning";
import ComponentItem, { IReturnComponent } from "./ComponentItem";
import { COMPONENT_LIST } from "../graphql/components.queries";
import Validations from "../../../../../common/components/Validations/Validations";
import { DataQuery, CourseComponent } from "../../../../../models/ISchema";
import { IParams } from "../../../../../models/IParams";
import { CardState, Loading } from "@foris/avocado-ui";
import css from "./addLinkSection.module.scss";

interface IAddLinkSection {
  courseId: number;
  externalErrors?: {
    type?: string;
    text: string;
  }[];
  onShowNextStep?: (show: boolean) => void;
  onValidForm?: (valid: boolean) => void;
  onCreateSections?: (sections: IReturnComponent[]) => void;
}

const CreateLink: React.FC<IAddLinkSection> = ({
  courseId,
  externalErrors = [],
  onShowNextStep,
  onValidForm,
  onCreateSections,
}: IAddLinkSection) => {
  const client = useApolloClient();
  const { scenario }: IParams = useParams();
  const [componentList, setComponentList] = useState<CourseComponent[]>(null);
  const [createSections, setCreateSections] = useState<IReturnComponent[]>(null);
  const [isLoadingComponents, setIsLoadingComponents] = useState(false);

  const handleSetCreateSections = (sections: IReturnComponent[]) => {
    setCreateSections(sections);
    onCreateSections?.(sections);
  };

  /**
   * Validations create section (example)
   */
  const validations = useMemo(() => {
    let countOldSection = 0;
    let countNotSelect = 0;
    let countNewSection = 0;
    const componentCount = componentList ? componentList.length : null;
    let objValidation: IErrorWarning = {
      type: "ERROR",
      message: "Error genérico",
    };
    const validationErrorWarning: IErrorWarning[] = [];

    if (!createSections) return validationErrorWarning;

    createSections.forEach(value => {
      if (value.typeSection === "OLD_SECTION") {
        countOldSection++;
        if (!value.section) countNotSelect++;
      }
      if (value.typeSection === "NEW_SECTION") countNewSection++;
    });

    if (componentCount && createSections.length === componentCount) {
      // validate one new_section
      if (countNewSection === 0) {
        objValidation = {
          type: "ERROR",
          message: "Se requiere al menos una sección nueva.",
        };
        validationErrorWarning.push(objValidation);
      }

      // validate old_section and select a section
      if (countOldSection > 0 && countNotSelect > 0) {
        objValidation = {
          type: "ERROR",
          message: "Se requiere seleccionar alguna sección.",
        };
        validationErrorWarning.push(objValidation);
      }
    }

    return validationErrorWarning;
  }, [createSections, componentList]);

  const externalValidations = useMemo(() => {
    return externalErrors.map(error => {
      return {
        type: (error?.type ? String(error?.type)?.toUpperCase() : "ERROR") as ErrorTpe,
        message: error?.text,
      };
    });
  }, [externalErrors]);

  /**
   * List of components by courseId
   */
  const getComponentList = useCallback(async () => {
    try {
      setIsLoadingComponents(true);
      const variables = {
        scenarioId: scenario,
        filter: {
          fields: {
            courseId: {
              eq: courseId,
            },
          },
        },
      };

      const { data } = await client.query({ query: COMPONENT_LIST, variables });
      const components: DataQuery = data.data;

      setComponentList(components.courseComponents.items);
      setIsLoadingComponents(false);
    } catch (e) {
      console.error(e);
      setIsLoadingComponents(false);
    }
  }, [scenario, client, courseId]);

  useEffect(() => {
    if (componentList === null) getComponentList();
  }, [getComponentList, componentList]);

  useEffect(() => {
    if (!createSections && componentList) {
      const sections = [];
      componentList.forEach(value => {
        const component: IReturnComponent = {
          courseComponent: value,
          typeSection: "NEW_SECTION",
          section: null,
        };
        sections.push(component);
      });
      handleSetCreateSections(sections);
    }
  }, [createSections, componentList]);

  useEffect(() => {
    onShowNextStep?.(
      createSections && componentList && componentList.length > 0 && !validations.length,
    );
  }, [validations, createSections, componentList]);

  useEffect(() => {
    onValidForm?.(!validations.length);
  }, [validations]);

  return (
    <section className={cx(css.cntCreateLink, "container-row")}>
      {isLoadingComponents && <Loading />}

      {componentList && createSections && (
        <>
          {componentList.length === 0 ? (
            <CardState title="Sin componentes" typeCard="warning" className={css.cntEmpty}>
              La asignatura seleccionada no tiene componentes configurados. Elige otra asignatura e
              inténtalo nuevamente.
            </CardState>
          ) : (
            <>
              <h3 className={cx(css.cntCreateLink_title, "col_12")}>Componentes:</h3>
              <ul className={cx(css.listComponent, "container-row")}>
                {componentList &&
                  componentList.map(value => {
                    return (
                      <ComponentItem
                        key={value.id}
                        courseComponent={value}
                        courseId={courseId}
                        onCallback={value => {
                          const sections = [...createSections];
                          const findComponent = sections.findIndex(
                            cmp => cmp.courseComponent.id === value.courseComponent.id,
                          );
                          if (findComponent >= 0) sections.splice(findComponent, 1);
                          sections.push(value);
                          handleSetCreateSections(sections);
                        }}
                      />
                    );
                  })}
              </ul>

              <Validations
                validations={[...validations, ...externalValidations]}
                onCallback={(valueCheck: boolean) => console.debug(valueCheck)}
                className={css.validationItem}
              />
            </>
          )}
        </>
      )}
    </section>
  );
};

export default CreateLink;
