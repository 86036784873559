import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Package } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error, setPage, onChangeSizePerPage } = props;
  const route = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;
  const title = generateTitle(pageInfo?.total || 0, search, "paquete");
  const { t } = useTranslation();

  const getGroupsTotal = ({ links }: Package) => {
    let groupCount = 0;

    links?.forEach(link => {
      groupCount += link?.groups?.length || 0;
    });

    const total = (links?.length || 0) + groupCount;
    return getColumnCell(total);
  };

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.code")),
      renderCell: ({ code, id }: Package) => getLinkCell(route, id, code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "200px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.period")),
      renderCell: ({ population }: Package) => getColumnCell(population?.term?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.campus")),
      renderCell: ({ population }: Package) => getColumnCell(population?.campus?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.modality")),
      renderCell: ({ population }: Package) => getColumnCell(population?.modality?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.shift")),
      renderCell: ({ population }: Package) => getColumnCell(population?.shift?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.career")),
      renderCell: ({ population }: Package) =>
        getColumnCell(`${population?.program?.code} ${population?.program?.name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "270px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.curriculum")),
      renderCell: ({ population }: Package) => getColumnCell(population?.curriculum),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.level")),
      renderCell: ({ population }: Package) => getColumnCell(population?.level),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.package")),
      renderCell: ({ index }: Package) => getColumnCell(index),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.links")),
      renderCell: ({ links }: Package) => getColumnCell(links?.length),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.groups")),
      renderCell: getGroupsTotal,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.quota")),
      renderCell: ({ vacancies }: Package) => getColumnCell(vacancies),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
  ];
  return (
    <SearchTable
      onChangeSizePerPage={onChangeSizePerPage}
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.package.title") as DimensionNameEs}
      isNewTable
      advanceLink={`/editor/advanceSearch/package/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
