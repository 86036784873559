import { DAYS_ES, MONTHS_ES } from "@modules/booking/utils/format";
import moment from "moment";

const formats = {
  timeGutterFormat: "HH",
  dayFormat: (date: any, locale: "es" | "en" = "es") => {
    return moment(date)
      .locale(locale)
      .format("dddd")
      .substr(0, 3);
  },
  dateEsFormat: date => {
    const momentDate = moment(date);
    const dayIndex = momentDate.day() - 1;
    const dateVal = momentDate.date();
    const monthIndex = momentDate.month();

    return `${DAYS_ES?.[dayIndex]?.toLowerCase()} ${dateVal} de ${MONTHS_ES?.[monthIndex]}`;
  },
};

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const formatHoursDay = (date: Date) => {
  const format =
    date.getHours().toString().length > 1
      ? date.getHours().toString()
      : "0" + date.getHours().toString();
  return format;
};

const formatMinutesDay = (date: Date) => {
  const format =
    date.getMinutes().toString().length > 1
      ? date.getMinutes().toString()
      : "0" + date.getMinutes().toString();
  return format;
};

const getDateNumber = (date: number) => (date > 9 ? date : "0" + date);

const getFormattedDate = (date: Date | string) => {
  const momentDate = moment(date);
  const month = momentDate.month() + 1;
  const day = momentDate.date();
  const year = momentDate.year();
  const fullDate = `${year}-${getDateNumber(month)}-${getDateNumber(day)}`;

  return fullDate;
};

export { formats, days, formatHoursDay, formatMinutesDay, getFormattedDate };
