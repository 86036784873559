import React from "react";
import { RadioButton } from "@foris/avocado-suite";
import { IReturnComponent } from "./ComponentItem";
import cx from "classnames";
import CreateLink from "./CreateLink";
import css from "./addLinkSection.module.scss";

interface IAddLinkSection {
  courseId: number;
  termId: number;
  groupCode?: string;
  groupVacancies?: string;
  programId?: string | number;
  externalErrors?: {
    type?: string;
    text: string;
  }[];
  onShowNextStep?: (show: boolean) => void;
  onValidForm?: (isValid: boolean) => void;
  onCreateSections?: (sections: IReturnComponent[]) => void;
}

const AddLinkSection: React.FC<IAddLinkSection> = (props: IAddLinkSection) => {
  return (
    <article className={cx(css.cntAddLink, "container-row")}>
      <h3 className={css.cntAddLink_title}>Paso 2: Configuración de la Liga</h3>

      <div className={css.cntAddLink_sections}>
        <h4 className={css.sectionsTitle}>Agregar liga/secciones</h4>

        <div className={cx(css.cntOption, "container-row")}>
          <RadioButton className={css.cntOption_item} labelRight="Liga nueva" checked={true} />
          <RadioButton className={css.cntOption_item} labelRight="Liga existente" disabled={true} />
        </div>
      </div>

      <CreateLink {...props} />
    </article>
  );
};

export default AddLinkSection;
