import {
  BlocksAssignmentNotAllowed,
  ClassroomClash,
  ClassroomsAssignmentNotAllowed,
  InstructorAssignmentNotAllowed,
  InstructorClash,
  InstructorExceededWeeklyBlocks,
  IntervalsAssignmentNotAllowed,
  InvalidBlock,
  InvalidBlockForInstructor,
  InvalidBuildingForCourse,
  InvalidClassroomCapacity,
  InvalidClassroomsCapacityAssignment,
  InvalidComponentSize,
  InvalidFieldValidation,
  InvalidGroupEnrollment,
  InvalidInstructorTravel,
  InvalidInterval,
  InvalidLinkChanges,
  InvalidPackageTravel,
  LinkClash,
  PackageClash,
  BookingClassroomClash,
  SectionClash,
  StudentClash,
  ExternalGroupValidation,
  InvalidPastSessionEdition,
  InvalidSessionInHoliday,
  InstructorAssignmentRequired,
  Holiday,
} from "../ISchema";

export type ValidationErrorType =
  | ClassroomClash
  | BookingClassroomClash
  | InstructorClash
  | LinkClash
  | SectionClash
  | PackageClash
  | InvalidInstructorTravel
  | InvalidPackageTravel
  | InvalidBuildingForCourse
  | InvalidBlock
  | InvalidInterval
  | InvalidBlockForInstructor
  | StudentClash
  | InvalidComponentSize
  | InvalidClassroomCapacity
  | InvalidClassroomsCapacityAssignment
  | InvalidLinkChanges
  | InvalidGroupEnrollment
  | InvalidFieldValidation
  | InstructorAssignmentNotAllowed
  | IntervalsAssignmentNotAllowed
  | BlocksAssignmentNotAllowed
  | ClassroomsAssignmentNotAllowed
  | InstructorExceededWeeklyBlocks
  | ExternalGroupValidation
  | InvalidPastSessionEdition
  | InvalidSessionInHoliday
  | InstructorAssignmentRequired;

export interface IValidationError {
  incidence: ValidationErrorType | any;
}

const errors = {
  ClassroomClash: "Choque de sala",
  BookingClassroomClash: "Choque de sala en reservas",
  InstructorClash: "Choque en instructor",
  LinkClash: "Choque en liga",
  SectionClash: "Choque en sección",
  PackageClash: "Choque en paquete",
  InvalidInstructorTravel: "Traslado de docente",
  InvalidPackageTravel: "Traslado de estudiantes",
  InvalidBuildingForCourse: "Edificio no permitido",
  InvalidBlock: "Rango de horarios inválido",
  InvalidBlockForInstructor: "Horario fuera de disponibilidad docente configurada",
  ExceededAssignableBlocksUpcomingYear: "Horas docente excedidas - año siguiente",
  ExceededAssignableBlocksCurrentYear: "Horas docente excedidas - año actual",
};

class SessionByType {
  private incidence: ValidationErrorType;
  constructor(error: IValidationError) {
    this.incidence = error.incidence;
  }

  private packageIncidenceMsg = (incidence: PackageClash) => {
    const complete =
      incidence.package.complete !== null
        ? incidence.package.complete
          ? "completo, "
          : "incompleto, "
        : "";
    const type = incidence.package.type !== null ? "*" : "";

    const countVacancies = incidence.package.vacancies ? incidence.package.vacancies : 0;
    const vacancies = countVacancies
      ? `${countVacancies} vacante${countVacancies > 1 ? "s" : ""}`
      : "";

    const opt =
      incidence.package.hasPackageRules !== null
        ? incidence.package.hasPackageRules
          ? "obligatorio, "
          : "optativo, "
        : "";
    const msgTitle = errors[incidence.__typename];
    return `${type}  ${msgTitle} ${incidence.package.code} (${type} ${complete} ${opt} ${vacancies}) `;
  };

  public getMessage() {
    switch (this.incidence.__typename) {
      case "PackageClash":
        return this.packageIncidenceMsg(this.incidence);
      case "ClassroomClash":
        return `${errors[this.incidence.__typename]} ${this.incidence?.classroom?.code}`;
      case "BookingClassroomClash":
        return `${errors[this.incidence.__typename]} ${this.incidence?.classroom?.code}`;
      case "InstructorClash":
        return `${errors[this.incidence.__typename]} ${this.incidence?.instructor?.code}`;
      case "InvalidBlockForInstructor":
        return `Horario del docente ${this.incidence?.instructor?.code} fuera de disponibilidad configurada`;
      case "StudentClash":
        const textCountStudent = this.incidence.count > 1 ? "estudiantes" : "estudiante";
        return `Solapamiento de horarios inscritos - ${this.incidence?.count} ${textCountStudent}`;
      case "InstructorExceededWeeklyBlocks":
        return `Horas semanales excedidas - Docente ${this.incidence.instructor?.code} (configuradas: ${this.incidence.assignableBlocks}, excedidas: ${this.incidence.exceededBlocks})`;
      case "InstructorAssignmentNotAllowed":
        return "No tiene permisos para realizar asignaciones de docentes";
      case "IntervalsAssignmentNotAllowed":
        return "No tiene permisos para realizar asignaciones de semanas";
      case "BlocksAssignmentNotAllowed":
        return "No tiene permisos para realizar asignaciones de horarios";
      case "ClassroomsAssignmentNotAllowed":
        return "No tiene permisos para realizar asignaciones de salas";
      case "ExternalGroupValidation":
        return `No se permite hacer cambios de horario debido a que el grupo tiene alumnos inscritos, se encuentra con cupo > 0 en el sistema de inscripciones, o bien, se está intentando cambiar a un salón de menor capacidad.`;
      case "InvalidClassroomsCapacityAssignment":
        return `Se está asignando un recurso de menor capacidad que el tamaño de la sección.`;
      case "InvalidInstructorTravel":
        return `Bloques insuficientes para el traslado entre el edificio del grupo A, ${this.incidence?.withFollowing?.fromBuilding?.name}-${this.incidence?.withFollowing?.fromBuilding?.code}, y el edificio del grupo B ${this.incidence?.withFollowing?.toBuilding?.name}-${this.incidence?.withFollowing?.toBuilding?.code}`;
      case "InvalidPastSessionEdition":
        return "Su rol no tiene permisos para modificar sesiones en semanas pasadas por el umbral de bloqueo.";
      case "InstructorAssignmentRequired":
        return "Sesión Requiere Docente";
      case "InvalidSessionInHoliday":
        const holidayName = ((this?.incidence?.holiday as unknown) as Holiday)?.name;
        return this?.incidence?.allowed
          ? holidayName
          : ` No tienes permiso para editar el día festivo "${holidayName}"`;

      default:
        return errors[this.incidence.__typename];
    }
  }
}

export default SessionByType;
