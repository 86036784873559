import { useApolloClient, useMutation } from "react-apollo";
import { NEW_LINK_CODE } from "../CreateSection/graphql/links.queries";
import LinkCreationMutation from "../CreateSection/graphql/linkCreation.mutation.graphql";
import { CubeMutation, LinkCrudResult } from "@models/ISchema";
import { IReturnComponent } from "../CreateSection/AddLinkSection/ComponentItem";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { getValidationErrorReason, linkValidation } from "@modules/vacancies/utils";

interface useCreateLinkProps {
  scenario: string;
  origin: string;
  workspace: string;
}

interface newSectionByLinkProps {
  courseId: string | number;
  dryRun?: boolean;
  termId: string;
  createSections: IReturnComponent[];
  programId?: string;
  groupCode?: string;
  groupVacancies?: string;
}

const useCreateLink = ({ scenario, origin, workspace }: useCreateLinkProps) => {
  const client = useApolloClient();
  const history = useHistory();
  const [codeLinkMutation] = useMutation(NEW_LINK_CODE, {});
  const [newLinkSectionMutation] = useMutation(LinkCreationMutation);
  const [linkCreationErrors, setLinkCreationErrors] = useState({
    validationArray: [],
    validationStandardArray: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const getCodeLink = async ({
    courseId,
    dryRun = false,
  }: {
    courseId: string | number;
    dryRun?: boolean;
  }) => {
    try {
      const variables = {
        originId: origin,
        scenarioId: scenario,
        courseId: courseId,
        dryRun,
      };
      const { data } = await codeLinkMutation({ variables });
      const code: CubeMutation = data.cube;
      return code.inferLinkCode;
    } catch (e) {
      console.debug(e);
    }
  };

  /**
   * Generate new Section by Link
   */
  const newSectionByLink = async (
    {
      courseId,
      termId,
      dryRun = false,
      createSections,
      programId,
      groupCode,
      groupVacancies,
    }: newSectionByLinkProps,
    linkCode: string,
  ) => {
    try {
      const assignSections = [];
      const courseComponentId = [];
      let errorsValidations = [];
      const errorStandard = [];

      createSections.forEach(value => {
        if (value.typeSection === "OLD_SECTION") {
          assignSections.push(parseInt(value.section.value));
        }
        if (value.typeSection === "NEW_SECTION") {
          courseComponentId.push({
            courseComponentId: parseInt(value.courseComponent.id),
          });
        }
      });

      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun,
          skipValidations: false,
          changesets: {
            creates: [
              {
                courseId,
                termId,
                code: linkCode,
                assignSections,
                createSections: courseComponentId,
                createGroup:
                  groupCode !== undefined
                    ? {
                        programId,
                        code: groupCode,
                        capacity: groupVacancies,
                      }
                    : undefined,
              },
            ],
          },
        },
      };

      const { data } = await newLinkSectionMutation({ variables });
      const payload: LinkCrudResult = data.cube.linksCrud;
      const validationErrors = payload?.payload?.creates?.[0]?.validationErrors;

      if (validationErrors?.length) {
        validationErrors.forEach((error: any) => {
          const internalValidations: any[] = error?.validationErrors ?? [];

          if (internalValidations.length) {
            internalValidations?.forEach(err => {
              const errorObj = getValidationErrorReason(err.reason);
              errorStandard.push(errorObj);
            });
          }

          // old validations
          if (error && error.__typename === "InvalidFieldValidation") {
            const errorObj = {
              ...getValidationErrorReason(error.reason),
              reason: error.reason,
            };
            errorsValidations.push(errorObj);
          } else if (error && error.__typename === "InvalidLinkChanges") {
            const errorLinks = linkValidation(error);
            errorsValidations = [...errorsValidations, ...errorLinks];
          } else if (error && error?.__typename === "InvalidGroupCodeValidation") {
            const errors = [
              ...errorsValidations,
              {
                type: "error",
                text: "Ingresa números enteros del 1 al 999",
                reason: error?.reason ?? "INVALID_GROUP_CODE",
              },
            ];

            if (String(groupCode).startsWith("0")) {
              errors.push({
                type: "error",
                text: "El número no puede ser antecedido por uno o más ceros (01, 001)",
                reason: error?.reason ?? "INVALID_GROUP_CODE",
              });
            }

            errorsValidations = errors;
          } else if (error && error.__typename === "GenericInvalidValidation") {
            const isGroupReason = ["GROUP_CODE_ALREADY_TAKEN"].includes(error?.reason);
            const errorArr = isGroupReason ? errorsValidations : errorStandard;
            const errorObj = getValidationErrorReason(error.reason);

            errorObj?.text
              ? errorArr.push({
                  ...errorObj,
                  reason: error.reason,
                })
              : console.error(error && error.__typename);
          } else {
            console.error(error && error.__typename);
          }
        });
      }

      return {
        canCreateLink: !validationErrors?.length,
        linkId: payload?.payload?.creates?.[0]?.link?.id,
        commited: !!payload?.commited,
        bundle: payload?.payload?.creates?.[0]?.link?.bundle,
        errors: {
          validationArray: errorsValidations,
          validationStandardArray: errorStandard,
        },
      };
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateLink = async (
    {
      courseId,
      termId,
      createSections,
      groupCode,
      programId,
      groupVacancies,
    }: newSectionByLinkProps,
    isSimulated = false,
  ) => {
    try {
      setIsLoading(true);
      // get code link
      const newCode = await getCodeLink({ courseId, dryRun: true });
      // create new section by link
      const { commited, bundle, errors } = await newSectionByLink(
        {
          courseId,
          termId,
          dryRun: isSimulated,
          createSections,
          groupCode,
          programId,
          groupVacancies,
        },
        newCode,
      );

      if (commited) {
        client.resetStore();
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/editor/vacancies/${contextUrl}/${bundle?.id}`);
      } else {
        setLinkCreationErrors({
          validationArray: errors.validationArray ?? [],
          validationStandardArray: errors.validationStandardArray ?? [],
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return {
    handleCreateLink,
    linkCreationErrors,
    isLoading,
  };
};

export default useCreateLink;
