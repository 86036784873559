import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@foris/avocado-icons";
import Options, { IOption } from "./Options";
import useClickOutside from "@common/hooks/useClickOutside";
import cx from "classnames";
import styles from "./dropFilter.module.scss";

export interface IDropFilterProps {
  onSelect: (value: IOption) => void;
  value?: string;
  options: Array<IOption>;
  onCallback?: () => void;
}

const DropFilter: React.FC<IDropFilterProps> = ({ value, options, onSelect, onCallback }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectOption, setSelectOption] = useState(value || "Seleccionar");

  const ref = useRef(null);
  const isClosingRef = useRef(isClosing);
  const isShowOptionsRef = useRef(showOptions);

  useEffect(() => {
    isClosingRef.current = isClosing;
    isShowOptionsRef.current = showOptions;
  }, [showOptions, isClosing]);

  useClickOutside({
    ref,
    onClickOutside: () => {
      if (isClosingRef.current || !isShowOptionsRef.current) return;
      setIsClosing(true);
    },
  });

  const toggleShowOptions = (show: boolean) => {
    setShowOptions(show);

    if (show) {
      onCallback?.();
    }
  };

  const handleSelectDrop = (value: IOption | null) => {
    const valueLabel = value ? value.label : selectOption;

    setSelectOption(valueLabel);
    setIsClosing(true);

    if (value) {
      onSelect?.(value);
    }
  };

  const handleAnimationEnd = () => {
    if (isClosing) {
      setIsClosing(false);
      toggleShowOptions(false);
    }
  };

  return (
    <section ref={ref} className={styles.dropFilter}>
      <p
        className={styles.dropFilter_text}
        onClick={() => {
          if (isClosing) return;

          if (!showOptions) {
            toggleShowOptions(true);
          } else {
            setIsClosing(true);
          }
        }}
      >
        {selectOption}
        <Icon
          className={cx(styles.chevronIcon, showOptions && !isClosing && styles.chevronIcon__open)}
          name="chevron-down"
          size="md"
          color="neutral-90"
        />
      </p>

      {showOptions && (
        <Options
          className={cx(isClosing && styles.dropFilterOptions__close)}
          options={options}
          onAnimationEnd={handleAnimationEnd}
          onSelect={handleSelectDrop}
        />
      )}
    </section>
  );
};

export default DropFilter;
