import { Dimension } from "../../../models/IDimension";
import i18n from "@config/i18n";

export const getOptionsFilter = () => {
  return [
    {
      label: i18n.t("dimension.classroom.title"),
      value: Dimension.classroom,
    },
    {
      label: i18n.t("dimension.instructor.title"),
      value: Dimension.instructor,
    },
    {
      label: i18n.t("dimension.course.title"),
      value: Dimension.course,
    },
    {
      label: i18n.t("dimension.link.title"),
      value: Dimension.league,
    },
    {
      label: i18n.t("dimension.section.title"),
      value: Dimension.section,
    },
    {
      label: i18n.t("dimension.population.title"),
      value: Dimension.population,
    },
    {
      label: i18n.t("dimension.package.title"),
      value: Dimension.package,
    },
    {
      label: i18n.t("dimension.group.title"),
      value: Dimension.group,
    },
  ];
};

export const getQueryString = (value: string) => {
  let queryString = "";
  switch (value) {
    case Dimension.instructor:
      queryString = "instructors";
      break;
    case Dimension.classroom:
      queryString = "classrooms";
      break;
    case Dimension.course:
      queryString = "coursesWithSections";
      break;
    case Dimension.section:
      queryString = "sections";
      break;
    case Dimension.population:
      queryString = "populations";
      break;
    case Dimension.league:
      queryString = "links";
      break;
    case Dimension.package:
      queryString = "packages";
      break;
    case Dimension.group:
      queryString = "groups";
      break;
  }
  return queryString;
};
