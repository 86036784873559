import queryString from "query-string";
import i18next from "i18next";
import { DimensionNameEs } from "../../../models/IDimension";

export const variablesSearch = (
  scenario: string,
  origin: string,
  params: queryString.ParsedQuery<string>,
  page: number,
  useFilterPagination?: boolean,
  sizePerPage?: number,
) => {
  const variables = {
    scenarioId: scenario,
    originId: origin,
    filterId: params?.advance,
    filter: {
      page: page,
      size: sizePerPage ?? 20,
      searchTerm: params?.search,
    },
    pagination: {
      page: page,
      size: sizePerPage ?? 20,
    },
  };
  if (params?.search) {
    delete variables.filterId;
    delete variables.pagination;
  }
  if (params?.advance) {
    delete variables.filter;
    delete variables.originId;
  }

  if (useFilterPagination) {
    delete variables.pagination;

    variables.filter = {
      pagination: {
        page: page,
        size: sizePerPage ?? 20,
        searchTerm: params?.search,
      },
    } as any;
  }

  return variables;
};

export const generateTitle = (total = 0, search: string, dimension: DimensionNameEs) =>
  search
    ? `${total} ${i18next.t("search-results.text-result")} "${search}"`
    : `${total} ${i18next.t("search-results.text-two-option")} ${dimension}`;

export const getAdvancedSelectorsFilters = ({
  campusId,
  buildingId,
  classroomTypeId,
}: {
  campusId?: string;
  buildingId?: string;
  classroomTypeId?: string;
}) => {
  const filterArray = [];

  if (campusId) filterArray.push({ building: { campusId: { eq: campusId } } });
  if (buildingId) filterArray.push({ buildingId: { eq: buildingId } });
  if (classroomTypeId) filterArray.push({ classroomTypeId: { eq: classroomTypeId } });

  return filterArray;
};
