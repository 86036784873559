import React, { ReactNode } from "react";
import { useEffect, useState } from "react";
import { Box, Heading } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import cx from "classnames";
import css from "./dropdownBox.module.scss";

interface DropdownBoxProps {
  className?: string;
  externalOpen?: boolean;
  title: string;
  children?: ReactNode;
  hasAction?: boolean;
}

const DropdownBox = ({
  className = "",
  externalOpen,
  title,
  children,
  hasAction = true,
}: DropdownBoxProps) => {
  const [isOpen, setIsOpen] = useState(externalOpen);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(externalOpen);
  }, [externalOpen]);

  return (
    <Box className={cx(css.dropdownBox, isOpen && css.dropdownBox__isOpen, className)}>
      {!hasAction ? (
        <div className={css.dropdownBox_head}>
          <Heading type="h3" className={css.headTitle}>
            {title}
          </Heading>
        </div>
      ) : (
        <button type="button" className={css.dropdownBox_head} onClick={toggleDropdown}>
          <Heading type="h3" className={css.headTitle}>
            {title}
          </Heading>

          <Icon className={css.headIcon} name="chevron-down" size="md" color="neutral-70" />
        </button>
      )}
      {hasAction && <div className={css.dropdownBox_content}>{children}</div>}
    </Box>
  );
};

export default DropdownBox;
