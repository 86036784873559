import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: LinkFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      links(filter: $filter) {
        items {
          id
          code
          vacancies
          bundle {
            term {
              code
            }
          }
          course {
            id
            name
            code
            courseComponents {
              id
              component {
                id
                code
                name
              }
            }
            curriculum {
              program {
                campus {
                  id
                  name
                  code
                }
              }
            }
          }
          sectionsCount
          totalBlocks
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
