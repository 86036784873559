import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CardNotification, Button } from "@foris/avocado-suite";
import { useHistory, useParams } from "react-router-dom";
import { IParams } from "../../../models/IParams";
import css from "./empty-results.module.scss";

interface PropsEmpty {
  dimension?: string;
  search?: string;
}

const EmptyResults: FC<PropsEmpty> = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={css.cntEmpty}>
      <CardNotification
        className={css.cntEmpty_cardNotification}
        state="info"
        title={t("empty-results.title")}
      >
        {t("empty-results.description")}
      </CardNotification>
      <div className={css.cntEmpty_actionsBtn}>
        <Button variant="secondary" onClick={() => history.push("/editor/")}>
          {t("empty-results.btn-home")}
        </Button>
        {origin && scenario && workspace && (
          <Button
            onClick={() => history.push(`/editor/advanceSearch/${workspace}/${scenario}/${origin}`)}
          >
            {t("empty-results.btn-advanced-search")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyResults;
