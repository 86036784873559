import React, { useContext, useEffect, useState } from "react";
import { isEmpty, lensProp, set } from "ramda";
import { Card, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { useHistory, useParams } from "react-router-dom";
import { BookingContext } from "../../context/BookingContext";
import { Types as SearchTypes } from "../../context/search.reducer";
import { Types as RequestTypes } from "../../context/request.reducer";
import { Types as SavedRequestTypes } from "../../context/savedRequest.reducer";
import { Classroom } from "@models/ISchema";
import useClassroomBookingsMutation from "../../hooks/useClassroomBookingsMutation";
import css from "./cardBooking.module.scss";

interface ICardResultsProps {
  items: Classroom[];
}

const CardBooking: React.FC<ICardResultsProps> = (props: ICardResultsProps) => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const { state, dispatch } = useContext(BookingContext);
  const [bookingId, setBookingId] = useState<string>(null);
  const [ignoreMutationResult, setIgnoreMutationResult] = useState<boolean>(false);
  const [mutationResult, submitClassroomBookingsMutation] = useClassroomBookingsMutation({
    origin,
    scenario,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Boolean(state?.search?.currentBooking)) {
      setBookingId(state?.search?.currentBooking?.id);
    }
  }, []);

  useEffect(() => {
    if (ignoreMutationResult) {
      return;
    }

    const { result, error } = mutationResult;

    if (isEmpty(result) && !error) return;

    const booking = result?.payload?.updates[0]?.booking;

    if (!error) {
      if (Boolean(booking)) {
        setIgnoreMutationResult(true);

        // cleaning
        dispatch({ type: SavedRequestTypes.clearSavedData, payload: null });
        dispatch({ type: RequestTypes.ClearRequest, payload: null });
        dispatch({ type: SearchTypes.SetView, payload: "search" });

        dispatch({ type: SearchTypes.SetCurrentBooking, payload: booking });
        dispatch({ type: SearchTypes.ClearSearch, payload: null });
        dispatch({
          type: RequestTypes.SetSnackbar,
          payload: { display: true, message: "Sala actualizada exitosamente", error: false },
        });
        dispatch({
          type: RequestTypes.SetUserResponsible,
          payload: { value: booking?.responsible?.id, error: false },
        });
        dispatch({
          type: RequestTypes.SetEventType,
          payload: { value: booking?.type?.id, error: false },
        });

        // redirect to the created booking (remember that booking updates
        // deletes the edited booking and creates a new one)
        history.push(`/booking/detail/${contextUrl}/${booking?.id}`);
      }
    } else {
      setIgnoreMutationResult(true);
      dispatch({
        type: RequestTypes.SetSnackbar,
        payload: { display: true, message: "Ocurrió un error. Sala no actualizada", error: true },
      });
      history.push(`/booking/detail/${contextUrl}/${state?.search?.currentBooking?.id}`);
    }
  }, [mutationResult]);

  const handleSubmitRequest = async (classroom: Classroom) => {
    setIsLoading(true);

    const booking = set(lensProp("classroom"), classroom, state?.request?.editedBooking);
    await submitClassroomBookingsMutation(booking, false, true);

    setIsLoading(false);
  };

  const handleCardClick = (classroom: Classroom, requestPath: string) => {
    if (!!state?.search?.currentBooking) {
      handleSubmitRequest(classroom);
      return;
    }

    dispatch({ type: SearchTypes.SetClassroom, payload: classroom });
    history.push(requestPath);
  };

  return (
    <>
      {isLoading && <Loading />}

      {props.items.map(classroom => {
        const contextPath = `${workspace}/${scenario}/${origin}`;
        const requestPath = `/booking/request/${contextPath}/${classroom.id}${
          Boolean(bookingId) ? `/${bookingId}` : ""
        }`;
        return (
          <Card.Simple
            key={classroom.id}
            className={cx(css.cardResult, "container-row", "col_12")}
            onClick={() => handleCardClick(classroom, requestPath)}
          >
            <Card.Content className={cx(css.cardResult_content)}>
              <p className={cx(css.cardResult_classroomName)}>{classroom.name}</p>
              <div className={cx(css.cardResult_item)}>
                <p className={cx(css.label)}>Edificio</p>
                <p className={cx(css.info)}>{classroom.building.name}</p>
              </div>
              <div className={cx(css.cardResult_item)}>
                <p className={cx(css.label)}>Tipo</p>
                <p className={cx(css.info)}>{classroom.classroomType.name}</p>
              </div>
              <div className={cx(css.cardResult_item)}>
                <p className={cx(css.label)}>Capacidad</p>
                <p className={cx(css.info)}>{classroom.capacity}</p>
              </div>
              <div className={cx(css.cardResult_item)}>
                <p className={cx(css.label)}>Piso</p>
                <p className={cx(css.info)}>{classroom?.floor ?? "-"}</p>
              </div>
            </Card.Content>
          </Card.Simple>
        );
      })}
    </>
  );
};

export default CardBooking;
