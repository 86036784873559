import React, { useState, useEffect, useCallback } from "react";
import { useMutation } from "react-apollo";
import { useParams } from "react-router-dom";
import { Loading } from "@foris/avocado-ui";
import cx from "classnames";
import Header from "./Header";
import TotalList from "./TotalList";
import Validations from "./Validations";
import GroupList from "./GroupList";
import { IObjValidationError, groupValidation } from "../utils";
import useUpdateGroupIsActive from "../hooks/useUpdateGroupIsActive";
import Modal from "@components/modal/ModalState/ModalState";
import { IGroup, ILeague, IGroupValue, IBundleTerm } from "../contextVacancies/ContextVacancies";
import { MUTATION_DISABLE_GROUP, UPDATE_GROUP_VISIBILITY } from "./../graphql/groups.queries";
import { CubeMutation, GroupValidationErrorUnion } from "../../../models/ISchema";
import { IParams } from "../../../models/IParams";
import css from "./groups.module.scss";

export interface IGroupsProps {
  linkId: string;
  item: ILeague;
  bundleTerm?: IBundleTerm;
  index: number;
  setValues: any;
  activeEdit: boolean;
  openGroup?: any;
  validationArray: Array<IObjValidationError>;
  setValidationArray: any;
  setDisabledConfirm: any;
  onMigrateGroup?: (groupId?: string | number) => void;
}

const Groups: React.FC<IGroupsProps> = ({ setValues, index, ...props }: IGroupsProps) => {
  const {
    item,
    linkId,
    activeEdit,
    openGroup,
    validationArray,
    setValidationArray,
    setDisabledConfirm,
    bundleTerm,
    onMigrateGroup,
  } = props;
  const { origin, scenario }: IParams = useParams();
  const [countVacancies, setCountVacancies] = useState(null);
  const [activeDialogRemove, setActiveDialogRemove] = useState(false);
  const [activeDialogOpen, setActiveDialogOpen] = useState(false);
  const [confirmGroupIsActiveUpdate, setConfirmGroupIsActiveUpdate] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup>(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [disableGroupMutation] = useMutation(MUTATION_DISABLE_GROUP, {});
  const [openGroupMutation] = useMutation(UPDATE_GROUP_VISIBILITY, {});
  const [updateGroupIsActiveResponse, updateGroupIsActive] = useUpdateGroupIsActive(
    origin,
    scenario,
  );
  const [handleUpdateGroupIsActiveResponse, setHandleUpdateGroupIsActiveResponse] = useState<
    boolean
  >(false);

  const totalEnrollments = [];
  const totalWaitList = [];

  const getInitialState = () => {
    const countGroupVacancies: Array<IGroupValue> = [];
    item?.groups?.forEach((group: IGroup) => {
      const groupValue: IGroupValue = {
        linkId,
        group,
        value: group.vacancies,
      };
      countGroupVacancies.push(groupValue);
    });
    setCountVacancies(countGroupVacancies);
  };

  const compareGroups = (a: IGroup, b: IGroup) => {
    let comparison = 0;
    if (a.label > b.label) comparison = 1;
    else if (a.label < b.label) comparison = -1;
    return comparison;
  };

  const orderGroups: Array<IGroup> = item.groups.sort(compareGroups);

  const onChangeInput = (value: any, indexGroup: number) => {
    const newValue = value ? parseInt(value) : 0;
    const findGroup: IGroupValue = countVacancies[indexGroup];
    findGroup.value = newValue;
    const totalVacancies = [...countVacancies];
    totalVacancies.splice(indexGroup, 1);
    totalVacancies.splice(indexGroup, 0, findGroup);
    setCountVacancies(totalVacancies);
  };

  const handleDisabledGroup = (group: IGroup) => {
    setActiveDialogRemove(true);
    setSelectedGroup(group);
  };

  const handleActivedGroup = (group: IGroup) => {
    setActiveDialogOpen(true);
    setSelectedGroup(group);
  };

  const handleToggleGroupActiveness = (group: IGroup) => {
    setConfirmGroupIsActiveUpdate(true);
    setSelectedGroup(group);
  };

  const handleUpdateGroupIsActive = async () => {
    setConfirmGroupIsActiveUpdate(false);
    setActiveLoading(true);
    await updateGroupIsActive(selectedGroup?.id, !selectedGroup?.isActive);
    setHandleUpdateGroupIsActiveResponse(true);
  };

  useEffect(() => {
    const { group: returnedGroup, commited, errors } = updateGroupIsActiveResponse;
    if (handleUpdateGroupIsActiveResponse) {
      if (returnedGroup && commited) {
        selectedGroup.openGroup = Boolean(returnedGroup?.visibleForEnrollment);
        selectedGroup.isActive = Boolean(returnedGroup?.isActive);
      }
      if (errors?.length) {
        const formattedErrors = (errors ?? [])?.map(() => ({
          type: "error",
          // for now, the only supported error is the one for the
          // `GROUP_VACANCIES_EXTERNAL_VALIDATION` reason
          text: `No se permite hacer cambios de horario debido a que el\
              grupo tiene alumnos inscritos, se encuentra con cupo > 0 en el \
              sistema de inscripciones, o bien, se está intentando cambiar a un salón de menor capacidad.`,
          leagueCode: item?.code,
        }));
        setValidationArray(formattedErrors);
      }
      setActiveLoading(false);
      setHandleUpdateGroupIsActiveResponse(false);
    }
  }, [updateGroupIsActiveResponse]);

  const handleDisabledMutationGroup = async () => {
    try {
      setActiveLoading(true);
      setActiveDialogRemove(false);

      if (selectedGroup) {
        const variables = {
          originId: parseInt(origin),
          scenarioId: parseInt(scenario),
          groupId: parseInt(selectedGroup.id),
        };

        const data = await disableGroupMutation({ variables });
        const dataMutation: CubeMutation = data.data.cube;
        const validationErrors: Array<GroupValidationErrorUnion> =
          dataMutation.disableGroup.validationErrors;
        let errorsValidations = [];

        if (validationErrors && validationErrors.length > 0) {
          validationErrors.forEach((error: GroupValidationErrorUnion) => {
            const link = dataMutation.disableGroup.link;
            const errorLinks = groupValidation([error], link);
            errorsValidations = [...errorsValidations, ...errorLinks];
          });

          setValidationArray(errorsValidations);
        }

        if (dataMutation.disableGroup.commited) {
          setActiveDialogRemove(false);
          window.location.reload();
        } else {
          setActiveLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      setActiveDialogRemove(false);
    }
  };

  const handleOpendMutationGroup = async () => {
    try {
      setActiveLoading(true);
      setActiveDialogOpen(false);

      if (selectedGroup) {
        const variables = {
          originId: parseInt(origin),
          scenarioId: parseInt(scenario),
          groupId: parseInt(selectedGroup.id),
          visible: !selectedGroup.openGroup,
        };

        const data = await openGroupMutation({ variables });
        const dataMutation: CubeMutation = data.data.cube;

        const validationErrors: Array<GroupValidationErrorUnion> =
          dataMutation.updateGroupVisible.validationErrors;
        let errorsValidations = [];

        if (validationErrors && validationErrors.length > 0) {
          validationErrors.forEach((error: GroupValidationErrorUnion) => {
            const link = dataMutation.updateGroupVisible.link;
            const errorLinks = groupValidation([error], link);
            errorsValidations = [...errorsValidations, ...errorLinks];
          });

          setValidationArray(errorsValidations);
        } else {
          selectedGroup.openGroup = !selectedGroup.openGroup;
        }

        setActiveLoading(false);
      }
    } catch (e) {
      console.error(e);
      setActiveDialogOpen(false);
    }
  };

  const handleSetValues = useCallback(
    (count, i) => {
      setValues(count, i);
    },
    [setValues],
  );

  useEffect(() => {
    if (!countVacancies) getInitialState();
  });

  useEffect(() => {
    handleSetValues(countVacancies, index);
  }, [countVacancies, index, handleSetValues]);

  return (
    <>
      {activeLoading && <Loading />}

      {confirmGroupIsActiveUpdate && selectedGroup && (
        <Modal
          typeState="warning"
          title="¿Estás seguro?"
          show={true}
          textButtonPrincipal="Confirmar"
          onClickPrincipal={handleUpdateGroupIsActive}
          textButtonSecondary="Cancelar"
          onClickSecondary={() => setConfirmGroupIsActiveUpdate(false)}
        >
          {`Si confimas esta acción dejarás al grupo ${selectedGroup?.label} como ${
            selectedGroup?.isActive ? "inactivo" : "activo"
          }.`}
        </Modal>
      )}
      {activeDialogRemove && selectedGroup && (
        <Modal
          typeState="error"
          title="¿Estás seguro?"
          iconState="trash"
          show={activeDialogRemove}
          textButtonPrincipal="Confirmar"
          onClickPrincipal={handleDisabledMutationGroup}
          textButtonSecondary="Cancelar"
          onClickSecondary={() => setActiveDialogRemove(false)}
        >
          {`Si confirmas esta acción eliminarás el grupo ${selectedGroup?.label}. Para revertir esta eliminación deberás
          crear nuevamente el grupo.`}
        </Modal>
      )}
      {activeDialogOpen && selectedGroup && (
        <Modal
          typeState="warning"
          title="¿Estás seguro?"
          show={activeDialogOpen}
          textButtonPrincipal="Confirmar"
          onClickPrincipal={handleOpendMutationGroup}
          textButtonSecondary="Cancelar"
          onClickSecondary={() => setActiveDialogOpen(false)}
        >
          {selectedGroup.openGroup
            ? `Si confirmas esta acción cerrarás el grupo ${selectedGroup?.label}.`
            : `Si confirmas esta acción abrirás el grupo ${selectedGroup?.label}.`}
        </Modal>
      )}

      <section className={cx(css.cntEdit)}>
        <Header indexList={index} />
        {orderGroups.map((group: IGroup, indexGroup: number) => {
          totalEnrollments.push(group.enrollment);
          totalWaitList.push(group.waitList);
          let value: any = "";
          if (countVacancies) {
            value = countVacancies[indexGroup] ? countVacancies[indexGroup].value : 0;
            value = value === 0 ? 0 : value;
          }
          return (
            <GroupList
              key={group.id}
              activeEdit={activeEdit}
              group={group}
              indexGroup={indexGroup}
              valueVacancies={value}
              bundleTerm={bundleTerm}
              onChange={(value: any) => {
                onChangeInput(value, indexGroup);
                setValidationArray([]);
              }}
              onClick={() => {
                handleDisabledGroup(group);
              }}
              onClickOpen={() => {
                handleActivedGroup(group);
              }}
              onClickIsActive={() => {
                handleToggleGroupActiveness(group);
              }}
              onMigrateGroup={onMigrateGroup}
            />
          );
        })}
        <TotalList
          totalEnrollments={totalEnrollments}
          totalWaitList={totalWaitList}
          indexList={index}
          countVacancies={countVacancies}
          edhCanEdit={item?.course?.edhCanEdit}
          onClick={() => openGroup()}
          groupsCount={item?.groups?.length ?? 0}
        />
        {/* Mensajes de error */}
        {validationArray && validationArray.length > 0 && (
          <Validations
            item={item}
            setDisabledConfirm={setDisabledConfirm}
            validationArray={validationArray}
          />
        )}
      </section>
    </>
  );
};

export default Groups;
