import React from "react";
import cx from "classnames";
import DetailsItems from "../DetailsItem/DetailsItems";
import css from "./details.module.scss";

interface IDetail {
  data: any;
  active?: boolean;
  children?: React.ReactNode;
}

const Details: React.FC<IDetail> = (props: IDetail) => {
  const { data, active, children } = props;
  if (data && !Array.isArray(data[0])) {
    return (
      <section className={cx(css.cntDetails, active && css.cntDetails__active)}>
        <div className={!!children ? css.cntDetails_content : ""}>
          <DetailsItems items={data || []} />
        </div>
        {children}
      </section>
    );
  }

  return (
    <section className={cx(css.cntDetails, active && css.cntDetails__active)}>
      <div className={!!children ? css.cntDetails_content : css.cntDetails_expand}>
        {data &&
          data.map((rows: any, index: number) => <DetailsItems items={rows || []} key={index} />)}
      </div>
      {children}
    </section>
  );
};

export default Details;
