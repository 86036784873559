import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classrooms(filter: $filter) {
        items {
          id
          code
          name
          usable
          capacity
          allowedOverflow
          floor
          assignment {
            usagePercentage
            chairsOccupationPercentage
          }
          classroomType {
            id
            code
            name
            __typename
          }
          building {
            id
            name
            code
            __typename
            campus {
              id
              code
              name
              __typename
            }
          }
          __typename
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
