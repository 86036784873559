import React, { useEffect, useMemo, useState } from "react";
import { CourseMultiKey } from "../../../../shared/selectors";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import TermSelect from "../../../../../modules/vacancies/Groups/TermSelect";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import css from "./selectors.module.scss";

interface ISelectorsReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
  groupCode?: string;
  groupVacancies?: string;
}

interface ISelectorsProps {
  onReturn: (value: ISelectorsReturn) => void;
}

const Selectors: React.FC<ISelectorsProps> = (props: ISelectorsProps) => {
  const { onReturn } = props;
  const [idTerm, setIdTerm] = useState(null);
  const [objCourse, setObjCourse] = useState<ISelectorsReturn>(null);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedModality, setSelectedModality] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");

  const isRequiredFieldsSelected = !!(selectedCampus || selectedModality || selectedProgram);
  const courseKey = useMemo(() => `${selectedCampus}-${selectedModality}-${selectedProgram}`, [
    selectedCampus,
    selectedModality,
    selectedProgram,
  ]);

  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  useEffect(() => {
    if (objCourse && idTerm) {
      onReturn({
        ...objCourse,
        idTerm,
      });
    }
  }, [idTerm, objCourse]);

  return (
    <>
      <section className={css.contentAddSection}>
        <h3 className={css.contentAddSection_title}>Paso 1: Selección de Asignatura y Periodo</h3>

        <div className={css.contentAddSection_groupFields}>
          <CampusSelect
            className={css.field}
            label
            onCallback={(campus: RegularOption) => setSelectedCampus(campus?.value || "")}
          />
          <ModalitySelect
            className={css.field}
            label
            shouldHasDepartment={false}
            onCallback={(modality: RegularOption) => setSelectedModality(modality?.value || "")}
          />
          <ProgramSelect
            className={css.field}
            label
            campus={selectedCampus}
            modality={selectedModality}
            shouldHaveFilters={false}
            filterBy="modality"
            onCallback={(program: RegularOption) => setSelectedProgram(program?.value || "")}
          />
        </div>

        <div className={css.contentAddSection_columFields}>
          <CourseMultiKey
            key={courseKey}
            label
            onCallback={newSelectorReturn => setObjCourse(newSelectorReturn)}
            className={css.field}
            campusId={selectedCampus}
            modalityId={selectedModality}
            programId={selectedProgram}
            isDisabled={!isRequiredFieldsSelected}
          />
        </div>

        <div className={css.contentAddSection_columFields}>
          <TermSelect sendValues={(values: any) => setTerm(values)} />
        </div>
      </section>
    </>
  );
};

export default Selectors;
