import React from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbItem } from "@foris/avocado-suite";
import Header from "../../../../common/components/Header/Header";

interface IHeaderEdit {
  event: any;
  createSession?: boolean;
  component?: any;
  sectionId?: any;
  vacancies?: number;
  title?: string;
  dimension?: string;
  breadcrumbItems?: BreadcrumbItem[];
}

const HeaderItemEdit: React.FC<IHeaderEdit> = (props: IHeaderEdit) => {
  const {
    event,
    createSession,
    component,
    sectionId,
    vacancies,
    title,
    dimension,
    breadcrumbItems,
  } = props;
  const { t } = useTranslation();
  const courseComponent =
    event.resource && event.resource.info && event.resource.info.courseComponent
      ? event.resource.info.courseComponent
      : "";
  const stripeProperties = event.stripeProperties ? event.stripeProperties : null;
  const blocksCount =
    event.resource && event.resource.blocksCount ? event.resource.blocksCount : null;
  const infoEvent = event.resource && event.resource.info ? event.resource.info : null;

  const moreDetails = [
    {
      title: t("dimension.section.delete-section.header.more-details.section"),
      content: !createSession ? event.sectionId : sectionId,
    },
    {
      title: t("dimension.section.delete-section.header.more-details.component"),
      content: !createSession ? courseComponent && courseComponent.component.code : component,
    },
    {
      title: t("dimension.section.delete-section.header.more-details.blocks"),
      content: blocksCount ? blocksCount : "",
    },
    {
      title: t("dimension.section.delete-section.header.more-details.vacancies"),
      content: !createSession ? infoEvent && infoEvent.section.vacancies : vacancies,
    },
    {
      title: t("dimension.section.delete-section.header.more-details.stripes"),
      content: stripeProperties
        ? stripeProperties.map((stripeItem: any) => stripeItem.stripe.name)
        : "",
    },
  ];

  const titleHeader = event.title ? event.title : title;
  const topTitleHeader = event.sessionId
    ? `${"dimension.section.delete-section.label"} ${event.sessionId}`
    : `${t("dimension.section.delete-section.title")} ${sectionId}`;

  return (
    <Header
      dimension={dimension}
      moreDetails={moreDetails}
      title={titleHeader}
      topTitle={topTitleHeader}
      breadcrumbItems={breadcrumbItems}
    />
  );
};

export default HeaderItemEdit;
