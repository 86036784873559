import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@foris/avocado-suite";
import { getHeadCell } from "@dimensions/utils/tableUtils";
import { ContextDisabledSession, IContextDisabled } from "./ContextDisabled/ContextDisabled";

interface ITableSessionProps {
  dataTable: any;
  sectionCheck: boolean;
}

const TableSession: React.FC<ITableSessionProps> = (props: ITableSessionProps) => {
  const { dataTable, sectionCheck } = props;
  const { t } = useTranslation();
  const context: IContextDisabled = useContext(ContextDisabledSession);
  const [data, setData] = useState(dataTable);

  const allCheck = useMemo(() => {
    const selectedItems = [...data].filter(item => item?.dataSession?.checked);
    return selectedItems.length === data.length;
  }, [data]);

  const dataCheck = (row: any, value: boolean) => {
    const cloneData = [...data];
    const objIndex = cloneData.findIndex((item: any) => item.id === row.id);
    cloneData[objIndex].dataSession.checked = value;
    setData(cloneData);
  };

  const allDataCheck = (value: boolean) => {
    if (sectionCheck && allCheck) {
      return;
    }

    const cloneData = [...data];
    cloneData.forEach((item: any) => {
      item.dataSession.checked = value;
    });
    setData(cloneData);
  };

  useEffect(() => {
    const dataCheck = [];
    data.forEach((item: any) => {
      if (item.dataSession.checked) {
        dataCheck.push(item.dataSession);
      }
    });
    context.setSessionData(dataCheck);
  }, [data]);

  useEffect(() => {
    allDataCheck(sectionCheck);
  }, [sectionCheck]);

  const columns = [
    {
      header: getHeadCell(t("dimension.section.delete-section.table.header.session")),
      accesor: "id",
      key: "id",
    },
    {
      header: getHeadCell(t("dimension.section.delete-section.table.header.session-schedule")),
      accesor: "blockRange",
      key: "blockRange",
    },
    {
      header: getHeadCell(t("dimension.section.delete-section.table.header.instructors")),
      accesor: "instructors",
      key: "instructors",
    },
    {
      header: getHeadCell(t("dimension.section.delete-section.table.header.class-room")),
      accesor: "classRooms",
      key: "classRooms",
    },
    {
      header: getHeadCell(t("dimension.section.delete-section.table.header.vacancies")),
      accesor: "vacancies",
      key: "vacancies",
    },
  ];

  return (
    <Table
      rowOptions={{
        isDisabled: () => sectionCheck,
        selectorType: "checkbox",
        onSelectAll: allDataCheck,
        isSelected: (row: any) => row?.dataSession?.checked,
        isAllSelected: allCheck,
        onRowSelect: (isSelected: boolean, row: any) => {
          dataCheck(row, isSelected);
        },
      }}
      data={data}
      columns={columns}
    />
  );
};

export default TableSession;
