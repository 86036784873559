import React, { useState, useRef, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Text } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import { ContextEDH } from "@context/ContextEDH";
import { Types } from "@context/base.reducer";
import styles from "./switchLocale.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";

const options = [
  { value: "en", label: "locale.en" },
  { value: "es", label: "locale.es" },
];

const SwitchLocale = () => {
  const ref = useRef(null);
  const { state, dispatch } = useContext(ContextEDH);
  const {
    i18n: { changeLanguage },
    t,
  } = useTranslation();
  const [dropdown, setDropdown] = useState(false);

  const locale = state.base.locale;

  const handleChangeLocale = () => {
    const newLocale = locale === "en" ? "es" : "en";
    changeLanguage(newLocale);
    dispatch({ type: Types.setLocale, payload: newLocale });
    localStorage.setItem("locale", newLocale);
  };

  useClickOutside({
    ref,
    onClickOutside: () => setDropdown(false),
  });

  return (
    <div className={styles.switchLocale} ref={ref}>
      <button
        type="button"
        className={cx(styles.switchLocale_btn, dropdown && styles.switchLocale_btn__active)}
        onClick={() => setDropdown(!dropdown)}
      >
        <Text type="md" className={styles.btn_label}>
          {t("locale.selector.title")}
        </Text>
      </button>

      {dropdown && (
        <ul className={styles.switchLocale_dropdown}>
          {options?.map(option => (
            <button
              key={option.value}
              type="button"
              className={cx(styles.item, locale === option.value && styles.item__active)}
              onClick={handleChangeLocale}
            >
              <Text type="md" className={styles.item_label}>
                {t(option.label)}
              </Text>
              {locale === option.value && (
                <Icon className="iconCheck" name="check" size="md" color="neutral-70" />
              )}
            </button>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SwitchLocale;
