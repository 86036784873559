import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table, Pager, Heading } from "@foris/avocado-suite";
import { DataGrid } from "@foris/avocado-ui";
import { ApolloError } from "apollo-client/errors/ApolloError";
import { DimensionNameEs } from "../../../models/IDimension";
import { PageInfo } from "@models/ISchema";
import { Header } from "@common/components";
import cx from "classnames";
import SearchTableSkeleton from "./SearchTable.skeleton";
import EmptyResults from "../../../common/components/EmptyResults/EmptyResults";
import css from "./searchTable.module.scss";

export interface ISearchTable {
  search: string;
  pageCount: number;
  setPage: (value: number) => void;
  total: number;
  page: number;
  loading: boolean;
  error: ApolloError;
}

interface SearchTableProps {
  title: string;
  dimension: DimensionNameEs;
  advanceLink: string;
  columns: any[];
  data: any;
  loading?: boolean;
  search?: string;
  error?: any;
  pageInfo?: PageInfo;
  setPage?: (value: number) => void;
  isNewTable?: boolean;
  onChangeSizePerPage?: (value: number) => void;
}

const SearchTable = (props: SearchTableProps) => {
  const {
    title,
    data,
    columns,
    advanceLink,
    setPage,
    loading,
    search,
    error,
    dimension,
    pageInfo,
    isNewTable,
    onChangeSizePerPage,
  } = props;

  const history = useHistory();
  const [internalSizePerPage, setInternalSizePerPage] = useState(20);

  const pagination = {
    onChange: !loading ? setPage : () => null,
    total: pageInfo?.total,
    page: pageInfo?.page,
    size: internalSizePerPage,
  };

  const handleChangeSizePerPage = (value: number) => {
    setInternalSizePerPage(value);
    onChangeSizePerPage?.(value);
  };

  const nonSortableColumns = useMemo(() => {
    const columnsSet = new Set<string>();

    columns.forEach(({ header }) => {
      columnsSet.add(header);
    });

    return columnsSet;
  }, [columns]);

  const { t } = useTranslation();

  return (
    <section className={cx(css.cntResults)}>
      <Header
        className={css.searchResults}
        dimension={dimension}
        title={
          <Heading darkMode className={css.searchResults_header} type="h1">
            {title}
          </Heading>
        }
        breadcrumbItems={[
          {
            title: t("dimension.instructor.breadcrumb.previous"),
            path: "/",
          },
          {
            title: dimension,
          },
        ]}
      />

      <div className={cx(css.cntResults_table)}>
        {loading ? (
          <SearchTableSkeleton columns={columns?.length} rows={internalSizePerPage} />
        ) : !data || data?.length === 0 || error ? (
          <EmptyResults dimension={dimension} search={search} />
        ) : (
          <>
            <div className={cx(css.header)}>
              <Button
                onClick={() => history.push(advanceLink)}
                variant="secondary"
                rightIcon="chevrons-right"
              >
                {t("search-results.btn-advanced-search")}
              </Button>
            </div>

            {!isNewTable ? (
              <DataGrid
                columns={columns}
                batch={data}
                nonSortableColumns={nonSortableColumns}
                onHeaderClick={() => null}
                pagination={pagination}
              />
            ) : (
              <>
                <Table columns={columns} data={data ?? []} desktopVariant="regular" />
                <Pager
                  {...pagination}
                  labelRowsPerPage={t("search-results.table.pager.label-rows-per-page")}
                  showRowsPerPage={!!onChangeSizePerPage}
                  pageSizeOptions={[10, 20]}
                  onRowsPerPageChange={(option: any) => handleChangeSizePerPage?.(option?.value)}
                  classname={{ global: css.cntResults_pagerContainer }}
                />
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default SearchTable;
