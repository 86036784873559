import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Section } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getUsableCell,
} from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error, setPage, onChangeSizePerPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "sección");
  const route = `/scheduler/editor/section/${workspace}/${scenario}/${origin}`;
  const { t } = useTranslation();

  const getInstructorName = ({ sessions }: Section) => {
    const totalInstructors = sessions.map(({ assignment }) => assignment?.instructor?.name);
    const uniqueInstructors = Array.from(new Set(totalInstructors));
    return getColumnCell(uniqueInstructors?.length);
  };

  const getClassrooms = ({ sessions }: Section) => {
    const classrooms: any = {};

    sessions.forEach(({ assignment }) => {
      const code = assignment?.classroom?.code;

      if (code && !(code in classrooms)) {
        classrooms[code] = code;
      }
    });

    const classroomsKeys = Object.keys(classrooms);

    return getColumnCell(classroomsKeys.length);
  };

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.code")),
      renderCell: ({ id, code }: Section) => getLinkCell(route, id, code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.campus")),
      renderCell: ({ course }: Section) =>
        getColumnCell(course?.curriculum?.program?.campus?.code || "-"),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "130px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.subject-code")),
      renderCell: ({ id, code, course }: Section) =>
        getLinkCell(route, id, `${code} ${course?.name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "260px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.career")),
      renderCell: ({ code }: Section) => getColumnCell(code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "250px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.curriculum")),
      renderCell: ({ course }: Section) => getColumnCell(course?.curriculum?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "140px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.total-instructors")),
      renderCell: getInstructorName,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "150px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.total-rooms")),
      renderCell: getClassrooms,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.schedule")),
      renderCell: ({ schedule }: Section) => getUsableCell(schedule?.resourceAssigment?.schedule),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.quota")),
      renderCell: ({ vacancies }: Section) => getColumnCell(vacancies),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
  ];
  return (
    <SearchTable
      onChangeSizePerPage={onChangeSizePerPage}
      isNewTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.section.title") as DimensionNameEs}
      advanceLink={`/editor/advanceSearch/section/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
