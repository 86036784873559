import gql from "graphql-tag";

export const classroomsAvailabilities = gql`
  query classroomsAvailabilities(
    $scenarioId: ID!
    $originId: ID!
    $classroomBookingId: ID
    $filter: ClassroomFilterInput
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomsAvailabilities(classroomBookingId: $classroomBookingId, filter: $filter) {
        items {
          id
          code
          name
          capacity
          floor
          building {
            id
            name
            campus {
              id
              name
            }
          }
          classroomType {
            id
            name
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
