import React from "react";
import { Icon } from "@foris/avocado-icons";
import { ThemeStore } from "@foris/avocado-suite";
import cx from "classnames";
import styles from "./switchTheme.module.scss";

const SwitchTheme: React.FC = () => {
  const themeMode = ThemeStore.useThemeStore(state => state.theme.mode);

  const toggleTheme = () => {
    const newThemeMode = themeMode === "dark" ? "light" : "dark";
    ThemeStore.setThemeMode(newThemeMode, true);
  };

  return (
    <div className={cx(styles.switchTheme, themeMode === "dark" && styles.switchTheme__darkMode)}>
      <label className={styles.switchTheme_label}>
        <input
          className={styles.switch}
          type="checkbox"
          onChange={toggleTheme}
          checked={themeMode === "dark" ? true : false}
        />
        <span className={styles.button}>
          <span className={styles.button_iconWrapper}>
            <Icon name={themeMode === "dark" ? "moon" : "sun"} size="sm" />
          </span>
        </span>
      </label>
    </div>
  );
};

export default SwitchTheme;
