import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { useTranslation } from "react-i18next";
import { DimensionNameEs } from "@models/IDimension";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error } = props;
  const title = generateTitle(pageInfo?.total, search, "asignatura");
  const route = `/scheduler/editor/course/${workspace}/${scenario}/${origin}`;

  const { t } = useTranslation();

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.code")),
      renderCell: ({ id, code }) => getLinkCell(route, id, code),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.name")),
      renderCell: ({ id, name }) => getLinkCell(route, id, name),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.campus")),
      renderCell: ({ curriculum }) => getColumnCell(curriculum?.program?.campus?.name || "-"),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.sections")),
      renderCell: ({ sectionsCount }) => getColumnCell(sectionsCount ?? "-"),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.demand-vacancies")),
      renderCell: ({ demand }) => getColumnCell(demand?.vacancies ?? "-"),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.demand-crosslist")),
      renderCell: ({ demand }) => getColumnCell(demand?.crosslist || "-"),
    },
  ];

  return (
    <SearchTable
      isNewTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={props.setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.course.title") as DimensionNameEs}
      advanceLink={`/editor/advanceSearch/course/${workspace}/${scenario}/${origin}`}
      onChangeSizePerPage={props.onChangeSizePerPage}
    />
  );
};

export default SearchResults;
