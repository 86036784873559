import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Population } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";
import SearchTable from "@dimensions/common/SearchTable";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, setPage, loading, error, onChangeSizePerPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "población");
  const route = `/scheduler/editor/population/${workspace}/${scenario}/${origin}`;
  const { t } = useTranslation();

  const getSubjectsTotal = ({ courses }: Population) => {
    const uniqueCourses = new Set(courses?.map(course => course?.id)).size;
    return getColumnCell(uniqueCourses);
  };

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.code")),
      renderCell: ({ id }: Population) => getLinkCell(route, id, id),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "170px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.period")),
      renderCell: ({ term }: Population) => getColumnCell(term?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "90px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.campus")),
      renderCell: ({ campus }: Population) => getColumnCell(campus?.code),
      desktopOptions: {
        headCellStyles: { width: "80px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.modality")),
      renderCell: ({ modality }: Population) => getColumnCell(modality?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.shift")),
      renderCell: ({ shift }: Population) => getColumnCell(shift?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.career")),
      renderCell: ({ program }: Population) => getColumnCell(`${program?.code} ${program?.name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "270px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.curriculum")),
      renderCell: ({ curriculum }: Population) => getColumnCell(curriculum),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.level")),
      renderCell: ({ level }: Population) => getColumnCell(level),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "90px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.packages")),
      renderCell: ({ packageCount }: Population) => getColumnCell(packageCount),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.subjects")),
      renderCell: getSubjectsTotal,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },

    {
      header: getHeadCell(t("search-results.dimension.population.table.header.vacancies")),
      renderCell: ({ size }: Population) => getColumnCell(size),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
  ];
  return (
    <SearchTable
      onChangeSizePerPage={onChangeSizePerPage}
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.population.title") as DimensionNameEs}
      advanceLink={`/editor/advanceSearch/population/${workspace}/${scenario}/${origin}`}
      isNewTable
    />
  );
};

export default SearchResults;
