import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Loading } from "@foris/avocado-ui";
import { useHistory, useParams } from "react-router-dom";
import { ContextEDH } from "@context/ContextEDH";
import { Checkbox } from "@foris/avocado-suite";
import { IParams } from "@models/IParams";
import { IReturnComponent } from "./AddLinkSection/ComponentItem";
import cx from "classnames";
import Layout from "../../../../common/layout/Layout";
import Header from "../../../../common/components/Header/Header";
import AddLinkSection from "./AddLinkSection/AddLinkSection";
import Selectors from "./Selectors/Selectors";
import AddGroupSection from "./add-group-section";
import useCreateLink from "../hooks/useCreateLink";
import css from "./createSection.module.scss";

const CreateSection: React.FC<any> = () => {
  const { scenario, origin, workspace }: IParams = useParams();
  const history = useHistory();

  const { state } = useContext(ContextEDH);
  const [isInStep2, setIsInStep2] = useState(false);
  const [createSections, setCreateSections] = useState<IReturnComponent[]>([]);
  const [selectorInfo, setSelectorInfo] = useState(null);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [showGroupFormActivator, setShowGroupFormActivator] = useState(false);
  const [isGroupFormValid, setIsGroupFormValid] = useState(false);
  const [isLinkFormValid, setIsLinkFormValid] = useState(false);

  const canCreateGroupInBundleView = !!state?.base?.base?.user?.abilities
    ?.can_create_groups_in_bundle_view;
  const createGroupWithinLinkCreation = state?.base?.createGroupWithinLinkCreation;

  const handleReturnStep2 = data => {
    setSelectorInfo(data);
    setIsInStep2(true);
  };

  const courseId = selectorInfo && selectorInfo.idCourse ? selectorInfo.idCourse : null;
  const termId = selectorInfo && selectorInfo.idTerm ? selectorInfo.idTerm : null;
  const programId = selectorInfo && selectorInfo.idProgram ? selectorInfo.idProgram : null;

  const handleGroupFormChange = payload => {
    const { groupCode, groupVacancies } = payload;

    setSelectorInfo({
      ...selectorInfo,
      groupCode,
      groupVacancies,
    });
  };

  const handleShowGroupForm = (shouldShow: boolean) => {
    if (
      createGroupWithinLinkCreation === "DISABLED" ||
      !canCreateGroupInBundleView ||
      !termId ||
      !courseId
    ) {
      setShowGroupForm(false);
      setShowGroupFormActivator(false);
      return;
    }

    if (createGroupWithinLinkCreation === "OPTIONAL") {
      setShowGroupFormActivator(shouldShow);
      return;
    }

    setShowGroupForm(shouldShow);
  };

  const { handleCreateLink, isLoading, linkCreationErrors } = useCreateLink({
    scenario,
    origin,
    workspace,
  });

  const mutationErrors = useMemo(() => {
    const groupErrors = [];
    const linkErrors = [...(linkCreationErrors?.validationStandardArray ?? [])];

    linkCreationErrors?.validationArray?.forEach(error => {
      if (
        error?.reason && [
          "NUMERIC_GROUP_CODE_VALIDATION",
          "GROUP_CODE_ALREADY_TAKEN",
          "INVALID_GROUP_CODE",
        ]
      ) {
        groupErrors.push(error);
      } else {
        linkErrors.push(error);
      }
    });

    return {
      groupErrors,
      linkErrors,
    };
  }, [linkCreationErrors]);

  const handleSubmit = () => {
    handleCreateLink({
      courseId,
      termId: String(termId),
      createSections,
      groupCode: showGroupForm ? selectorInfo?.groupCode ?? undefined : undefined,
      programId: programId as string,
      groupVacancies: showGroupForm ? selectorInfo?.groupVacancies ?? undefined : undefined,
    });
  };

  useEffect(() => {
    if (state?.base?.createGroupWithinLinkCreation === "MANDATORY") {
      const abilities = state?.base?.base?.user?.abilities;

      (!abilities?.can_create_groups_in_bundle_view || !abilities?.can_edit_links_and_sections) &&
        history?.goBack();
    }
  }, [state?.base?.createGroupWithinLinkCreation]);

  return (
    <Layout contextSearch>
      {isLoading && <Loading />}

      <div className={css.cntCreate}>
        <Header title="Crear liga" />
        <section className={cx(css.content, "container-row")}>
          <Selectors onReturn={handleReturnStep2} />

          {isInStep2 && courseId && termId && (
            <AddLinkSection
              key={courseId}
              courseId={parseInt(courseId)}
              termId={parseInt(termId)}
              programId={programId}
              externalErrors={mutationErrors.linkErrors}
              onShowNextStep={handleShowGroupForm}
              onValidForm={setIsLinkFormValid}
              onCreateSections={setCreateSections}
            />
          )}

          {showGroupFormActivator && (
            <Checkbox
              className={css.content_groupFormActivator}
              labelRight="Crear grupo para Liga (opcional)"
              checked={showGroupForm}
              onChange={e => setShowGroupForm(e.target.checked)}
            />
          )}

          {showGroupForm && (
            <AddGroupSection
              key={`course-${courseId}`}
              externalErrors={mutationErrors.groupErrors}
              onValidForm={setIsGroupFormValid}
              onStateChange={handleGroupFormChange}
            />
          )}

          <div className={css.content_actions}>
            <Button
              className={css.cancelBtn}
              variant="outline"
              onClick={() => history?.replace("/home")}
            >
              Cancelar
            </Button>
            <Button
              disabled={
                (showGroupForm && !isGroupFormValid) || !courseId || !termId || !isLinkFormValid
              }
              onClick={handleSubmit}
            >
              Crear Liga
            </Button>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CreateSection;
