import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import queryString from "query-string";
import { megaSearch } from "../graphql/packageMegaSearch.query";
import { packageSearchQuery } from "../graphql/advanceSearch.queries";
import SearchResults from "./SearchResults";
import { IParams } from "../../../../models/IParams";
import { PackagePage } from "../../../../models/ISchema";
import Layout from "../../../../common/layout/Layout";
import { variablesSearch } from "../../utils/searchDimension";

const SearchPackage: React.FC = () => {
  const { origin, scenario }: IParams = useParams();
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [page, setPageCount] = useState(1);
  const variables = variablesSearch(scenario, origin, params, page, !params?.advance);
  const querySearch = params?.advance ? packageSearchQuery : megaSearch;

  const { loading, error, data } = useQuery(querySearch, { variables });
  let packages: PackagePage = data?.cube?.packages;
  if (params?.advance) packages = data?.filter?.packages;

  return (
    <Layout contextSearch>
      <SearchResults
        data={packages?.items}
        search={params?.search?.toString()}
        pageInfo={packages?.pageInfo}
        setPage={(value: number) => setPageCount(value)}
        loading={loading}
        error={error}
      />
    </Layout>
  );
};

export default SearchPackage;
