import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Instructor } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getPillCell,
  getUsableCell,
} from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import SearchTable from "@dimensions/common/SearchTable";
import { DimensionNameEs } from "@models/IDimension";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error, setPage, onChangeSizePerPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "docente");
  const route = `/scheduler/editor/instructor/${workspace}/${scenario}/${origin}`;
  const { t } = useTranslation();

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.code")),
      renderCell: ({ code, id }: Instructor) => getLinkCell(route, id, code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.name")),
      renderCell: ({ name, id }: Instructor) => getLinkCell(route, id, name),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "260px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.priority")),
      renderCell: ({ priority }: Instructor) => getColumnCell(priority),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "120px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.type-contract")),
      renderCell: ({ contract }: Instructor) => getColumnCell(contract),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "200px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.usable")),
      renderCell: ({ usable }: Instructor) => getUsableCell(usable),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "120px" },
      },
    },
    {
      header: getHeadCell(
        t("search-results.dimension.instructor.table.header.total-assigned-blocks"),
      ),
      renderCell: ({ assignment }: Instructor) => getColumnCell(assignment?.totalAssignedBlocks),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "200px" },
      },
    },
    {
      header: getHeadCell(
        t("search-results.dimension.instructor.table.header.occupancy-percentage"),
      ),
      renderCell: ({ assignment }: Instructor) => getPillCell(assignment?.usagePercentage),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "150px" },
      },
    },
  ];

  return (
    <SearchTable
      onChangeSizePerPage={onChangeSizePerPage}
      isNewTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.instructor.title") as DimensionNameEs}
      advanceLink={`/editor/advanceSearch/instructor/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
