import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import queryString from "query-string";
import { megaSearch } from "../graphql/groupMegaSearch.query";
import { groupSearchQuery } from "../graphql/advanceSearch.queries";
import SearchResults from "./SearchResults";
import { GroupPage } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import Layout from "../../../../common/layout/Layout";
import { variablesSearch } from "../../utils/searchDimension";

const SearchGroup = () => {
  const { origin, scenario }: IParams = useParams();
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPageCount] = useState(1);
  const variables = variablesSearch(scenario, origin, params, page, undefined, sizePerPage);
  const querySearch = params?.advance ? groupSearchQuery : megaSearch;

  const { loading, error, data } = useQuery(querySearch, { variables });
  let groups: GroupPage = data?.cube?.groups;
  if (params?.advance) {
    groups = data?.filter?.groups;
  }

  return (
    <Layout contextSearch>
      <SearchResults
        data={groups?.items}
        search={params?.search?.toString()}
        pageInfo={groups?.pageInfo}
        setPage={(value: number) => setPageCount(value)}
        loading={loading}
        error={error}
        onChangeSizePerPage={setSizePerPage}
      />
    </Layout>
  );
};

export default SearchGroup;
