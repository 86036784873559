import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Classroom } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getPillCell,
  getUsableCell,
} from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, setPage, loading, error, onChangeSizePerPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "sala");
  const route = `/scheduler/editor/classroom/${workspace}/${scenario}/${origin}`;
  const { t } = useTranslation();

  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.code")),
      renderCell: ({ id, code }: Classroom) => getLinkCell(route, id, code),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.name")),
      renderCell: ({ id, name }: Classroom) => getLinkCell(route, id, name),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.building")),
      renderCell: ({ building }: Classroom) => getColumnCell(building?.name),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "124px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.campus")),
      renderCell: ({ building }: Classroom) => getColumnCell(building?.campus?.code),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.floor")),
      renderCell: ({ floor }: Classroom) => getColumnCell(floor),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.classroom-type")),
      renderCell: ({ classroomType }: Classroom) => getColumnCell(classroomType?.name),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.capacity")),
      renderCell: ({ capacity }: Classroom) => getColumnCell(capacity),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.usable")),
      renderCell: ({ usable }: Classroom) => getUsableCell(usable),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "100px",
        },
      },
    },
    {
      header: getHeadCell(
        t("search-results.dimension.classroom.table.header.utilization-percentage"),
      ),
      renderCell: ({ assignment }: Classroom) => getPillCell(assignment?.usagePercentage),
    },
    {
      header: getHeadCell(
        t("search-results.dimension.classroom.table.header.occupation-percentage"),
      ),
      renderCell: ({ assignment }: Classroom) =>
        getPillCell(assignment?.chairsOccupationPercentage),
    },
  ];

  return (
    <SearchTable
      onChangeSizePerPage={onChangeSizePerPage}
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.classroom.title") as DimensionNameEs}
      isNewTable
      advanceLink={`/editor/advanceSearch/classroom/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
